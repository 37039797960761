import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ESortOption} from 'view/venue/NW2VenuesList/components/NW2VenuesSort/constants';

interface IFiltersState {
  venuesSortOption: ESortOption;
}

const initialState: IFiltersState = {
  venuesSortOption: ESortOption.RECOMMENDED,
};

export const filtersSlice = createSlice({
  name: 'filtersSlice',
  initialState,
  reducers: {
    setVenuesSortOption(state, action: PayloadAction<ESortOption>) {
      state.venuesSortOption = action.payload;
    },
  },
});
export const {setVenuesSortOption} = filtersSlice.actions;

export default filtersSlice.reducer;
