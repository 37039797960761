import React from 'react';
import DatePicker, {ReactDatePickerProps} from 'react-datepicker';

import {useAppSelector} from 'store/hooks';
import {TDateChangeFn} from '../NW2DatePicker.types';
import {TDateType} from 'types/dto/ISearch.types';
import {StyledWrapper} from './NW2DatePicker.styles';
import {getDpConfig} from '../utils';

interface IProps extends ReactDatePickerProps {
  date: TDateType;
  onChange: TDateChangeFn;
  className?: string;
}
export const NW2DatePicker = ({date, onChange, className, ...rest}: IProps) => {
  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);
  const locale = useAppSelector(({app}) => app.locale);

  return (
    <StyledWrapper className={className} isMobile={isMobile}>
      <DatePicker
        selected={date}
        onChange={onChange}
        {...getDpConfig(locale)}
        {...rest}
      />
    </StyledWrapper>
  );
};
