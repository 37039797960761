import React from 'react';
import {DateTime} from 'luxon';
import DatePicker, {
  ReactDatePickerCustomHeaderProps,
  ReactDatePickerProps,
} from 'react-datepicker';

import {NW2MultiDatePickerMobileWithCustomHeader} from './NW2MultiDatePickerMobileWithCustomHeader';

import {useAppSelector} from 'store/hooks';
import {ERoomType} from 'types/dto/ERoomType.type';
import {Wrapper} from './NW2MultiDatePicker.styles';
import {getDpConfig} from '../utils';

interface IProps extends ReactDatePickerProps {
  isSingleDayDatePicker?: boolean;
  withMonthYearDropdown?: boolean;
  showMonthYearDropdown?: boolean;
  hideCustomHeader?: boolean;
}
export const NW2MultiDatePicker = ({
  startDate,
  endDate,
  onChange,
  isSingleDayDatePicker,
  withMonthYearDropdown,
  showMonthYearDropdown = true,
  hideCustomHeader,
  ...rest
}: IProps) => {
  const {isMobile, isTablet} = useAppSelector(({app}) => app.deviceType);
  const locale = useAppSelector(({app}) => app.locale);
  const tabsRoomType = useAppSelector(({search}) => search.tabsRoomType);

  const configForMonthYearDropdown = withMonthYearDropdown
    ? {
        maxDate: DateTime.now().plus({years: 100}).toJSDate(),
        showMonthYearDropdown,
        scrollableMonthYearDropdown: true,
      }
    : {};

  const configForSingleDayDatePicker = isSingleDayDatePicker
    ? {
        selectsRange: false,
        endDate: null,
      }
    : {};

  const isWorkSpace = tabsRoomType === ERoomType.WORK_SPACE;
  const endDateForPicker = isWorkSpace ? null : endDate;

  const renderCustomHeader = ({
    monthDate,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    customHeaderCount,
  }: ReactDatePickerCustomHeaderProps) => {
    const displayedMonth = monthDate.getMonth();
    const displayedYear = monthDate.getFullYear();

    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();

    const showPrevButton =
      displayedMonth !== currentMonth || displayedYear !== currentYear;

    return (
      <>
        {customHeaderCount !== 1 && showPrevButton && (
          <button
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
            className='react-datepicker__navigation react-datepicker__navigation--previous'
          >
            <span className='react-datepicker__navigation-icon react-datepicker__navigation-icon--previous' />
          </button>
        )}
        <span className='react-datepicker__current-month'>
          {`${monthDate.toLocaleString('default', {
            month: 'long',
          })} ${displayedYear}`}
        </span>
        {customHeaderCount !== 0 && (
          <button
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
            className='react-datepicker__navigation react-datepicker__navigation--next'
          >
            <span className='react-datepicker__navigation-icon react-datepicker__navigation-icon--next' />
          </button>
        )}
      </>
    );
  };

  const datePickerConfig = {
    selected: startDate,
    onChange,
    startDate,
    endDate: endDateForPicker,
    selectsRange: !isWorkSpace,
    renderCustomHeader:
      isTablet || isMobile || isWorkSpace || hideCustomHeader
        ? undefined
        : renderCustomHeader,
    ...getDpConfig(locale),
    ...configForMonthYearDropdown,
    ...configForSingleDayDatePicker,
    ...rest,
  };

  return (
    <Wrapper isMobile={isMobile} withMonthYearDropdown={withMonthYearDropdown}>
      {isMobile ? (
        <NW2MultiDatePickerMobileWithCustomHeader {...datePickerConfig} />
      ) : (
        <DatePicker {...datePickerConfig} />
      )}
    </Wrapper>
  );
};
