import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {INITIAL_VENUE_DETAILS} from './constants';
import {EMPTY_OBJECT} from 'constants/app';
import {IVenuesStore, TRegistrationLinkData} from './types';
import {EAccommodationType, IPublicVenue} from 'types/dto/IPublicVenue';
import {IUnit} from 'types/dto/IUnit.types';
import {TVenueUnitBookingStatistics} from 'types/dto/IVenueUnitBookingStatisticsByDate.type';
import {getHmdRegLink} from 'utils/venueUtils';
import {IMergedVenue} from '../../types/search';

export const initialState: IVenuesStore = {
  loading: false,
  venueDetails: INITIAL_VENUE_DETAILS,
  multiVenueDetails: [],
  error: null,
  venueUnitBookingStatistics: EMPTY_OBJECT,
  venuesListActiveMarker: null,
  googleMapRefetch: false,
  isGoogleMapExpanded: false,
  spaceDetailsId: null,
  isVenuesListOpened: true,
  isDetailsDatepickerMobileShowed: false,
  isVenueDetailsMapVisible: false,
  isDetailsDatepickerShowed: false,
  isSearchTemplatePopupVisible: false,
  selectedMeetingRoomSeatingPlan: '',
  isMultiRoomsBookingNotAvailable: false,
  registrationLinkData: {
    hkey: '',
    registrationLink: '',
    status: null,
  },
  hasItemsInVenuesList: false,
};

const setFailureState = (
  state: IVenuesStore,
  action: PayloadAction<string>,
) => {
  state.loading = false;
  state.error = action.payload;
};

export const venuesSlice = createSlice({
  name: 'venues',
  initialState,
  reducers: {
    setIsVenuesListOpened: (state, action: PayloadAction<boolean>) => {
      state.isVenuesListOpened = action.payload;
    },
    setDetailsDatepickerMobileShowed: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.isDetailsDatepickerMobileShowed = action.payload;
    },
    setDatepickerDetailsShowed: (state, action: PayloadAction<boolean>) => {
      state.isDetailsDatepickerShowed = action.payload;
    },
    getBookingFailure: setFailureState,
    getVenueDetailsFailure: setFailureState,
    getRegistrationLinkDataFailure: setFailureState,
    getMultiVenueDetailsSuccess: (
      state,
      action: PayloadAction<{
        response: any[];
      }>,
    ) => {
      state.multiVenueDetails = action.payload.response;
      state.loading = false;
      state.error = null;
    },
    getVenueDetailsSuccess: (
      state,
      action: PayloadAction<{response: any; isMultiRooms: boolean}>,
    ) => {
      const {response, isMultiRooms} = action.payload;

      const venueGlobal: IPublicVenue = {
        ...response,
        units: response.units.map((el: IUnit) => ({
          ...el,
          id: el.unitId || el.id,
        })),
        accommodationId: response.id || response.accommodationId,
      };

      const isPublicVenue =
        venueGlobal.accommodationType === EAccommodationType.VENUE;

      const isMultiRoomsBookingNotAvailable = // todo check do we need it at all
        isMultiRooms &&
        !venueGlobal.allExtrasAvailable &&
        isPublicVenue &&
        !venueGlobal.isOffer;

      state.venueDetails = venueGlobal;
      state.isMultiRoomsBookingNotAvailable = isMultiRoomsBookingNotAvailable;
      state.loading = false;
      state.error = null;
    },

    // searchVenuesSuccess: (
    //   state,
    //   action: PayloadAction<{pageItems: any; filterDays: TSearchVenuesDay[]}>,
    // ) => {
    //   const availableRecords = action.payload.pageItems.filter(
    //     ({type}: IMergedVenue) => type !== EVenueType.NOT_AVAILABLE,
    //   ); // temp solution for filtering NOT_AVAILABLE venues
    //
    //   const sortOrder = [
    //     EVenueType.INSTANT_CORPORATE,
    //     EVenueType.INSTANT,
    //     EVenueType.RTB,
    //     EVenueType.RFP,
    //     EVenueType.RFP_WITH_WIP,
    //   ];
    //
    //   // Update the state
    //   state.loading = false;
    //   state.venueGlobalList = availableRecords.sort(
    //     (x: IMergedVenue, y: IMergedVenue) =>
    //       sortOrder.indexOf(x.type) - sortOrder.indexOf(y.type),
    //   );
    //   state.filterDays = action.payload.filterDays;
    // },

    confirmBookingRequest: (state) => {
      state.loading = true;
    },
    getRegistrationLinkDataRequest: (state) => {
      state.loading = true;
    },
    getVenueDetailsRequest: (state) => {
      state.loading = true;
      state.error = null;
    },

    setVenueUnitBookingStatistics: (
      state,
      action: PayloadAction<TVenueUnitBookingStatistics>,
    ) => {
      state.venueUnitBookingStatistics = action.payload;
    },

    setGoogleMapRefetch: (state, action: PayloadAction<boolean>) => {
      state.googleMapRefetch = action.payload;
    },
    setIsGoogleMapExpanded: (state, action: PayloadAction<boolean>) => {
      state.isGoogleMapExpanded = action.payload;
    },

    setSpaceDetailsId: (state, action: PayloadAction<number | null>) => {
      state.spaceDetailsId = action.payload;
    },
    setIsVenueDetailsMapVisible: (state, action: PayloadAction<boolean>) => {
      state.isVenueDetailsMapVisible = action.payload;
    },
    setIsSearchTemplatePopupVisible: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.isSearchTemplatePopupVisible = action.payload;
    },
    setMeetingRoomSeatingPlan: (state, action: PayloadAction<string>) => {
      state.selectedMeetingRoomSeatingPlan = action.payload;
    },
    clearVenueDetails: (state) => {
      state.venueDetails = INITIAL_VENUE_DETAILS;
      state.isMultiRoomsBookingNotAvailable = false;
      state.error = null;
      state.loading = false;
    },
    getRegistrationLinkDataSuccess: (
      state,
      action: PayloadAction<TRegistrationLinkData>,
    ) => {
      const link: string = action.payload.registrationLink;
      const shortLink = getHmdRegLink(link);

      state.registrationLinkData = {
        ...action.payload,
        registrationLink: shortLink,
      };
      state.loading = false;
    },
    setVenuesListActiveMarker: (
      state,
      action: PayloadAction<IMergedVenue | null>,
    ) => {
      state.venuesListActiveMarker = action.payload;
    },
    setHasItemsInVenuesList: (state, action: PayloadAction<boolean>) => {
      state.hasItemsInVenuesList = action.payload;
    },
  },
});

export const {
  setIsVenuesListOpened,
  setDetailsDatepickerMobileShowed,
  setDatepickerDetailsShowed,
  getBookingFailure,
  confirmBookingRequest,
  getVenueDetailsRequest,
  getVenueDetailsSuccess,
  getVenueDetailsFailure,
  getMultiVenueDetailsSuccess,
  setGoogleMapRefetch,
  setIsGoogleMapExpanded,
  setSpaceDetailsId,
  setIsVenueDetailsMapVisible,
  setIsSearchTemplatePopupVisible,
  setMeetingRoomSeatingPlan,
  clearVenueDetails,
  setVenueUnitBookingStatistics,
  getRegistrationLinkDataFailure,
  getRegistrationLinkDataRequest,
  getRegistrationLinkDataSuccess,
  setVenuesListActiveMarker,
  setHasItemsInVenuesList,
} = venuesSlice.actions;

export default venuesSlice.reducer;
