import React, {SyntheticEvent} from 'react';
import {useAppSelector} from 'store/hooks';

import NW2PopoverDrawer from 'view/components/NW2PopoverDrawer';
import Icon from 'view/components/Icon';
import {useClickOutside} from 'hooks/useClickOutside';
import {
  MobileHeaderTitle,
  SortPopoverContent,
  StyledDiv,
} from '../NW2VenuesSort.styles';
import {offsetDef} from 'styles/configs';

type TProps = {isPopoverVisible: boolean; togglePopoverVisibility: () => void};

const SortTextPopover = ({
  isPopoverVisible,
  togglePopoverVisibility,
}: TProps) => {
  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);
  const isTablet = useAppSelector(({app}) => app.deviceType.isTablet);
  const isResponsive = isMobile || isTablet;
  const clickOutsideCallback = () => {
    if (isPopoverVisible && isResponsive) {
      togglePopoverVisibility();
    }
  };
  const [ref] = useClickOutside(isPopoverVisible, clickOutsideCallback);

  const handleIconClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    togglePopoverVisibility();
  };

  return (
    <StyledDiv ref={ref}>
      <NW2PopoverDrawer
        header={isMobile && <MobileHeaderTitle>Recommended</MobileHeaderTitle>}
        body={
          <SortPopoverContent isMobile={isMobile}>
            "Recommended" prioritizes your search results based on key factors
            such as best offer and venue response time.
          </SortPopoverContent>
        }
        isMobile={isMobile}
        popoverWidth='200px'
        open={isResponsive ? isPopoverVisible : undefined}
        onClose={isResponsive ? togglePopoverVisibility : undefined}
        overlayClassName='sorting-popover'
        drawerBodyStyle={{padding: offsetDef}}
        clickable={isResponsive}
      >
        <StyledDiv onClick={handleIconClick}>
          <Icon icon='INFORMATION_CIRCLE' />
        </StyledDiv>
      </NW2PopoverDrawer>
    </StyledDiv>
  );
};

export default SortTextPopover;
