import React, {useCallback} from 'react';
import {InputNumber} from 'antd';
import {useAppDispatch, useAppSelector} from 'store/hooks';
import {useLocation} from 'react-router-dom';
import useToggle from 'hooks/useToggle';

import {NW2SearchDatePicker} from 'view/components/NW2SearchSection/components/SearchForm/components/NW2SearchDatePicker';
import NW2GoogleMapSearchInput from 'view/components/NW2SearchSection/components/SearchForm/components/NW2GoogleMapSearchInput/NW2GoogleMapSearchInput';
import InputBlock from './components/InputBlock/InputBlock';
import Icon from 'view/components/Icon';
import useLockedBody from 'hooks/useLockedBody';
import NW2Button from 'view/components/NW2Button';
import SeatingStyleInput from './components/SeatingStyleInput/SeatingStyleInput';
import LocalStorageService from 'infra/common/localStorage.service';
import {GroupsDatePicker} from './components/GroupsDatePicker/GroupsDatePicker';
import {GroupsRooms} from './components/GroupsRooms/GroupsRooms';
import NW2VenuesSort from 'view/venue/NW2VenuesList/components/NW2VenuesSort/NW2VenuesSort';

import {
  maximumParticipantsNumber,
  minimumParticipantsNumber,
  SEATING_STYLE_ID,
} from 'constants/app';
import {TNW2GoogleMapSearchInputData} from 'types/googleMap';
import {ERoomType} from 'types/dto/ERoomType.type';
import {checkBoolean} from 'utils/stringUtils';
import {preventNumberOperators} from 'utils/helpers';
import {setSearchFocusedInput} from 'store/search/searchSlice';
import {LOCATION_ID, DATE_ID, PARTICIPANTS_ID} from 'constants/app';
import {Routes} from 'constants/routes';

import {setHeaderCollapsed} from 'store/app/appSlice';
import {
  ButtonsContainer,
  Container,
  Content,
  EditSearchButton,
  EditSearchButtonBox,
  GoogleMapSearchInputWrapper,
} from './SearchForm.styles';

type TProps = {
  minimized: boolean;
  searchInputFocused: boolean;
  setSearchInputFocused: (data: boolean) => void;
  onSearch: (data: TNW2GoogleMapSearchInputData) => void;
  onSearchVenues: () => void;
  onFastSearch: () => void;
  googleMapSearchInputValue: string;
  onParticipantsChange: (data: number) => void;
  participants: number;
  onChangeInputLocation: () => void;
  isMultiDayChosen: boolean;
  isDatepickerInitialised: boolean;
  isVenuesListPage?: boolean;
  handleSetDatepickerInitialised: (data: boolean) => void;
};

function SearchForm({
  minimized,
  onSearch,
  onFastSearch,
  onSearchVenues,
  googleMapSearchInputValue,
  searchInputFocused,
  setSearchInputFocused,
  onParticipantsChange,
  participants,
  onChangeInputLocation,
  isMultiDayChosen,
  isDatepickerInitialised,
  handleSetDatepickerInitialised,
  isVenuesListPage,
}: TProps) {
  const dispatch = useAppDispatch();
  const {pathname} = useLocation();

  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);
  const focusedInput = useAppSelector(({app}) => app.searchFocusedInput);
  const isHeaderCollapsed = useAppSelector(({app}) => app.headerCollapsed);
  const tabsRoomType = useAppSelector(({search}) => search.tabsRoomType);

  const hasItemsInVenuesList = useAppSelector(
    ({venuesReducer}) => venuesReducer.hasItemsInVenuesList,
  );

  const [isVisibleSortMenu, toggleVisibleSortMenu] = useToggle(false);
  const {locked, setLocked} = useLockedBody(false);

  const onInputFocus = (inputId: string) => () => {
    dispatch(setSearchFocusedInput(inputId));
  };

  const onInputBlur = useCallback(() => {
    dispatch(setSearchFocusedInput(''));
  }, [dispatch]);

  const onClickHandler = useCallback(() => {
    if (isHeaderCollapsed) {
      dispatch(setHeaderCollapsed(false));
    }
  }, [isHeaderCollapsed, dispatch]);

  const onDateClickHandler = () => {
    onClickHandler();

    if (isMobile) {
      setLocked(!locked);
    }
  };

  const onParticipantsChangeHandler = (value: number | null) => {
    onParticipantsChange(value || 1);
  };

  const isMeetingRoom = tabsRoomType === ERoomType.MEETING_ROOM;
  const isGroups = tabsRoomType === ERoomType.GROUPS;

  const isFastSearchEnabled = checkBoolean(
    LocalStorageService.getByKey('isFastSearchEnabled'),
  );

  const isHomePage = pathname === Routes.mainLanding;

  const isAdvancedSearchView =
    isMeetingRoom && !isMultiDayChosen && (isFastSearchEnabled || isHomePage);

  const showSeatingStyleEditButton = !isFastSearchEnabled && !isHomePage;
  const showSortingOnMobile = isMobile && minimized && hasItemsInVenuesList;

  const seatingStyleLabel = showSeatingStyleEditButton ? null : 'seating style';

  return (
    <Container minimized={minimized}>
      <Content minimized={minimized}>
        <InputBlock
          htmlFor={LOCATION_ID}
          labelText='Search'
          isFocused={focusedInput === LOCATION_ID}
        >
          <GoogleMapSearchInputWrapper>
            <NW2GoogleMapSearchInput
              id={LOCATION_ID}
              searchInputFocused={searchInputFocused}
              setSearchInputFocused={setSearchInputFocused}
              onFocus={onInputFocus(LOCATION_ID)}
              onBlur={onInputBlur}
              placeholder='Where do you want to go?'
              onSearch={onSearch}
              inputValue={googleMapSearchInputValue}
              onClick={onClickHandler}
              isMobile={isMobile}
              onChangeInput={onChangeInputLocation}
            />
            {showSortingOnMobile && (
              <>
                <EditSearchButtonBox>
                  <EditSearchButton
                    minimized
                    size='small'
                    buttonType='tertiary'
                    icon={
                      <Icon size={18} clickable icon='DATA_TRANSFER_VERTICAL' />
                    }
                    onClick={toggleVisibleSortMenu}
                  />
                </EditSearchButtonBox>
                <NW2VenuesSort
                  toggleVisibleSortMenu={toggleVisibleSortMenu}
                  isVisibleSortMenu={isVisibleSortMenu}
                />
              </>
            )}
          </GoogleMapSearchInputWrapper>
        </InputBlock>

        <InputBlock
          htmlFor={DATE_ID}
          labelText='Date'
          isFocused={focusedInput === DATE_ID}
          flex={3}
          isHidden={isGroups || (isMobile && minimized)}
        >
          <NW2SearchDatePicker
            inputId={DATE_ID}
            onFocus={onInputFocus(DATE_ID)}
            onBlur={onInputBlur}
            onClickHandler={onDateClickHandler}
            isMeetingRoom={isMeetingRoom}
            isDatepickerInitialised={isDatepickerInitialised}
            handleSetDatepickerInitialised={handleSetDatepickerInitialised}
          />
        </InputBlock>

        {isGroups && !(isMobile && minimized) && (
          <>
            <GroupsDatePicker isVenuesListPage={isVenuesListPage} />
            <GroupsRooms onFocusHandler={onClickHandler} />
          </>
        )}

        <InputBlock
          htmlFor={PARTICIPANTS_ID}
          labelText={isMobile ? <Icon icon='PARTICIPANTS' /> : 'Participants'}
          isFocused={focusedInput === PARTICIPANTS_ID}
          isHidden={
            !isMeetingRoom ||
            (!isAdvancedSearchView && !isHomePage) ||
            (isMobile && minimized)
          }
        >
          <InputNumber
            id={PARTICIPANTS_ID}
            name={PARTICIPANTS_ID}
            type='number'
            precision={0}
            min={minimumParticipantsNumber}
            max={maximumParticipantsNumber}
            step={1}
            placeholder='How many?'
            onClick={onClickHandler}
            onFocus={onInputFocus(PARTICIPANTS_ID)}
            onBlur={onInputBlur}
            onChange={onParticipantsChangeHandler}
            onKeyDown={preventNumberOperators}
            value={participants}
            bordered={false}
            controls={false}
            size='small'
          />
        </InputBlock>

        {showSeatingStyleEditButton ? (
          <InputBlock
            isFocused={false}
            isHidden={!isMeetingRoom || (isMobile && minimized)}
            positionRelative
            flex={0}
          >
            <EditSearchButtonBox>
              <EditSearchButton
                minimized
                size='small'
                buttonType='tertiary'
                icon={<Icon size={18} clickable icon='NW2_EDIT' />}
                onClick={onSearchVenues}
              />
            </EditSearchButtonBox>
          </InputBlock>
        ) : (
          <InputBlock
            htmlFor={SEATING_STYLE_ID}
            labelText={seatingStyleLabel}
            isFocused={focusedInput === SEATING_STYLE_ID}
            isHidden={!isMeetingRoom || (isMobile && minimized)}
            positionRelative
            flex={2}
          >
            <SeatingStyleInput onClickInput={onClickHandler} />
          </InputBlock>
        )}
      </Content>

      {!minimized && (
        <ButtonsContainer>
          {(isAdvancedSearchView || isGroups) && (
            <NW2Button
              inverted
              buttonType='secondary'
              fullWidth={isMobile}
              onClick={onFastSearch}
            >
              Fast search
            </NW2Button>
          )}
          <NW2Button
            buttonType='primary'
            fullWidth={isMobile}
            onClick={onSearchVenues}
          >
            Search now
          </NW2Button>
        </ButtonsContainer>
      )}
    </Container>
  );
}

export default SearchForm;
