import React, {memo, useCallback, useMemo} from 'react';

import Icon from 'view/components/Icon';
import Image from 'view/components/Image';

//TODO:verify the necessity of this component
// import NW2AvailabilityBlock from './components/NW2AvailabilityBlock';//
import NW2PriceBlock from './components/NW2PriceBlock';
import TextValue from 'view/components/TextValue';
import CustomList from 'view/components/CustomList';
import NW2PopoverDrawer from 'view/components/NW2PopoverDrawer';
import TruncateText from 'view/components/TruncateText';
import DistanceComponent from 'view/common/DistanceComponent/DistanceComponent';

import {EAccommodationType, EVenueType} from 'types/dto/IPublicVenue';
import {pluralize} from 'utils/helpers';
import {TButtonBaseProps} from 'view/components/NW2Button/types';
import {EPointsOfInterest, EVenueTypes} from 'types/venue';
import {ERoomType} from 'types/dto/ERoomType.type';
import {useAppSelector} from 'store/hooks';
import {
  borderRadiusDef,
  lineHeightSm,
  NW2Gray800Color,
  officeImageHeight,
  offsetDef,
  offsetXSm,
  offsetXXSm,
  venueImageHeight,
} from 'constants/styleVars';
import {
  AvailabilityTag,
  ButtonsContainer,
  Content,
  CustomPopoverGlobalStyle,
  FlexList,
  ImageWrapper,
  LeftSide,
  RightSide,
  ShortDetails,
  ShortDetailsItem,
  SkeletonLeftSide,
  SkeletonRightSide,
  SkeletonRow,
  StyledButton,
  StyledCard,
  SubHeading,
  VenueItemBody,
  VenueItemHeader,
  VenueName,
} from './NW2VenuesListCard.styles';
import {getButtonType} from './helpers';
import {
  IUseShortListReturnProps,
  useShortListHandlers,
} from 'view/venue/Offer/hooks/useShortListHandlers';
import {IMergedVenue} from 'types/search';
import {useIsRequestForOfferBtnVisible} from 'view/venue/hooks/useIsRequestForOfferBtnVisible';
import {ENW2PopoverDrawerPlacement} from 'view/components/NW2PopoverDrawer/types';
import NW2StarsCount from './components/NW2StarsCount';

interface IProps extends Partial<IUseShortListReturnProps> {
  venue: IMergedVenue;
  roomType: ERoomType;
  viewDetails: (id: number) => void;
  onRequestForOfferClick?: (venue: IMergedVenue) => () => void;
  activeMarkerId?: number;
  isLoading?: boolean;
  buttonProps?: TButtonBaseProps;
  isAlternativesSection?: boolean;
}
const NW2VenuesListCard = ({
  venue,
  activeMarkerId,
  viewDetails,
  isLoading = false,
  roomType,
  onRequestForOfferClick,
  buttonProps,
  isAlternativesSection,
}: IProps) => {
  const {isDesktop, isTablet, isMobile, width} = useAppSelector(
    ({app}) => app.deviceType,
  );
  const searchString = useAppSelector(
    ({search}) => search.searchCriteria.searchString,
  );

  const {hasPriceItems, checkIsShortListItemAdded} = useShortListHandlers();

  const {
    id,
    accommodationId,
    location,
    currency,
    coverImageLink,
    name,
    totalPrice,
    accommodationType,
    // availability, // TODO: to be implemented on backend after venue unification
    // responseTime, // TODO: to be adjusted after backend is done
    accommodationBuildingType,
    totalBedrooms,
    totalMeetingRooms,
    pointsOfInterest,
    type,
  } = venue;

  const isCorporateVenue =
    accommodationType === EAccommodationType.CORPORATE_OFFICE;
  const isMeetingRoom = roomType === ERoomType.MEETING_ROOM;
  const isWorkDesk = roomType === ERoomType.WORK_SPACE;

  const hasInstantUnit =
    !!totalPrice && (type === EVenueType.INSTANT || isWorkDesk);

  const onButtonClick = useCallback(() => {
    const venueId = isWorkDesk ? Number(id) : accommodationId;

    viewDetails(venueId);
  }, [accommodationId, id, viewDetails, isWorkDesk]);

  const isShortlisted = checkIsShortListItemAdded?.(accommodationId);

  const [isRequestForOfferBtnShowed] = useIsRequestForOfferBtnVisible({
    isCorporateVenue,
  });

  const buttons = useMemo(() => {
    if (isAlternativesSection) {
      return (
        <StyledButton
          buttonType='primary'
          onClick={onButtonClick}
          size={isMobile ? 'large' : isTablet ? 'medium' : 'small'}
          fullWidth={isMobile}
          {...buttonProps}
        >
          view details
        </StyledButton>
      );
    }

    return (
      <>
        {isRequestForOfferBtnShowed && (
          <StyledButton
            buttonType='chip'
            size={isDesktop ? 'small' : 'medium'}
            active={isShortlisted}
            iconPlace='left'
            icon={
              isShortlisted && <Icon icon='NW2_TICK' transparent size={14} />
            }
            onClick={onRequestForOfferClick?.(venue)}
          >
            <span>
              {isShortlisted
                ? 'Shortlisted'
                : (width as number) < 354
                ? 'Request offer'
                : 'Request for offer'}
            </span>
          </StyledButton>
        )}

        {!!totalPrice && (
          <StyledButton
            buttonType={getButtonType(hasPriceItems)}
            onClick={onButtonClick}
            size={isDesktop ? 'small' : 'medium'}
            fullWidth={!isMeetingRoom}
            {...buttonProps}
          >
            Book
          </StyledButton>
        )}

        {isCorporateVenue && (
          <StyledButton
            buttonType='primary'
            onClick={onButtonClick}
            size={isDesktop ? 'small' : isTablet ? 'medium' : 'large'}
            fullWidth={isMobile}
            {...buttonProps}
          >
            Continue
          </StyledButton>
        )}
      </>
    );
  }, [
    buttonProps,
    hasPriceItems,
    isAlternativesSection,
    isCorporateVenue,
    isDesktop,
    isMeetingRoom,
    isMobile,
    isRequestForOfferBtnShowed,
    isShortlisted,
    isTablet,
    onButtonClick,
    onRequestForOfferClick,
    totalPrice,
    venue,
    width,
  ]);

  const getPointsOfInterestByType = (poiType: EPointsOfInterest) =>
    pointsOfInterest?.find(({type}) => poiType === type);

  const isShortDetailsVisible = isDesktop && !isCorporateVenue;
  const bookingTypeText = !totalPrice
    ? 'Request pricing'
    : hasInstantUnit
    ? 'Instant booking'
    : 'Request booking';

  const venueTypeText = isCorporateVenue
    ? isDesktop
      ? ''
      : 'Your company office'
    : EVenueTypes[accommodationBuildingType];

  const bedroomsText = `${pluralize('guest room', totalBedrooms)}`;
  const meetingRoomsText = `${pluralize('meeting room', totalMeetingRooms)}`;

  const imageHeight = isCorporateVenue ? officeImageHeight : venueImageHeight;
  const venueLocation = `${location.city}, ${location.country}`;

  const trainPoint = getPointsOfInterestByType(EPointsOfInterest.TRAIN_STATION);
  const airportPoint = getPointsOfInterestByType(EPointsOfInterest.AIRPORT);

  const distanceToTrain = trainPoint?.distance || 0;
  const distanceToAirport = airportPoint?.distance || 0;

  const locationPointName = `Distance from ${searchString.split(',')[0]}`;

  const listArray = [
    ...(!isCorporateVenue ? [{value: bookingTypeText}] : []),
    ...(venueTypeText ? [{value: venueTypeText}] : []),
    {...(totalMeetingRooms ? {value: meetingRoomsText} : null)},
    {...(totalBedrooms ? {value: bedroomsText} : null)},
  ];

  //TODO: check with business if they need number of meeting rooms in venue card
  //   const unitCapacity =
  //   roomType === ERoomType.MEETING_ROOM
  //     ? units?.length || 0
  //     : getWorkDeskCapacity(units);

  // const availableUnitsText = isDesktop
  //   ? pluralize(UNIT_TYPE_COPY[roomType], unitCapacity).toLowerCase()
  //   : `${unitCapacity} meeting`;
  //   const listArray = [
  //   ...(venueTypeText ? [{value: venueTypeText}] : []),
  //   {
  //     value: totalBedrooms
  //       ? `${availableUnitsText} & ${bedroomsText}`
  //       : `${availableUnitsText}`,
  //   },
  // ];

  const popoverPointsProps = {
    isMobile: false,
    popoverWidth: 'auto',
    placement: ENW2PopoverDrawerPlacement.BOTTOM,
    customGlobalStyle: <CustomPopoverGlobalStyle />,
  };

  const popoverNameProps = {
    isMobile: false,
    popoverWidth: 'auto',
    popoverBodyStyle: {
      padding: offsetXXSm,
    },
    overlayInnerStyle: {
      borderRadius: borderRadiusDef,
    },
  };

  const header = (
    <VenueItemHeader>
      <NW2StarsCount starsAmount={venue.amountOfStars} />

      <VenueName
        data-test-class='venuesListVenueName'
        onClick={onButtonClick}
        isSelected={venue.accommodationId === activeMarkerId}
      >
        {isAlternativesSection ? (
          <NW2PopoverDrawer body={<span>{name}</span>} {...popoverNameProps}>
            <>
              <TruncateText
                text={name}
                numberOfLines={2}
                alignChildren='left'
                hideToggler
              />
            </>
          </NW2PopoverDrawer>
        ) : (
          <span style={{display: 'inline-block'}}>
            <TruncateText text={name} numberOfLines={2} hideToggler />
          </span>
        )}
      </VenueName>

      <SubHeading>
        <TextValue
          size='small'
          onClick={onButtonClick}
          marginTop='0'
          lineHeight={lineHeightSm}
          color={NW2Gray800Color}
        >
          {venueLocation}
        </TextValue>

        <ShortDetails isVisible={!(isDesktop || isCorporateVenue)}>
          <ShortDetailsItem>
            <Icon icon='MAP_PIN_OUTLINE' />
            <span>
              <DistanceComponent distanceInKm={location.distance} />
            </span>
          </ShortDetailsItem>
        </ShortDetails>

        <AvailabilityTag isVisible={isCorporateVenue && isDesktop}>
          Space available
        </AvailabilityTag>
      </SubHeading>

      <AvailabilityTag isVisible={isCorporateVenue && isTablet} isTablet>
        Space available
      </AvailabilityTag>

      <ShortDetails isVisible={isShortDetailsVisible}>
        <NW2PopoverDrawer
          body={<>{locationPointName}</>}
          {...popoverPointsProps}
        >
          <ShortDetailsItem>
            <Icon icon='MAP_PIN_OUTLINE' />
            <span>
              <DistanceComponent distanceInKm={location.distance} />
            </span>
          </ShortDetailsItem>
        </NW2PopoverDrawer>

        {!!distanceToTrain && (
          <NW2PopoverDrawer
            body={<>{trainPoint?.name}</>}
            {...popoverPointsProps}
          >
            <ShortDetailsItem>
              <Icon icon='RAILROAD_METRO' />
              <span>
                <DistanceComponent distanceInKm={distanceToTrain} />
              </span>
            </ShortDetailsItem>
          </NW2PopoverDrawer>
        )}

        {!!distanceToAirport && (
          <NW2PopoverDrawer
            body={<>{airportPoint?.name}</>}
            {...popoverPointsProps}
          >
            <ShortDetailsItem>
              <Icon icon='PLANE' />
              <span>
                <DistanceComponent distanceInKm={distanceToAirport} />
              </span>
            </ShortDetailsItem>
          </NW2PopoverDrawer>
        )}
      </ShortDetails>
    </VenueItemHeader>
  );

  return (
    <div id={`venue-${accommodationId}`}>
      <StyledCard
        isCorporateVenue={isCorporateVenue}
        isDesktop={isDesktop}
        isTablet={isTablet}
        isSelected={isShortlisted}
        isAlternativesSection={isAlternativesSection}
      >
        <ImageWrapper showSkeletons={isLoading}>
          {!isLoading && (
            <Image
              alt={venue.name}
              src={coverImageLink}
              height={isDesktop ? imageHeight : 'auto'}
              borderRadius={borderRadiusDef}
              onClick={onButtonClick}
              isSearchVenue
            />
          )}
        </ImageWrapper>

        <Content>
          {isLoading ? (
            <>
              <SkeletonRow />
              <SkeletonLeftSide>
                <SkeletonRow />
                <SkeletonRightSide />
              </SkeletonLeftSide>
            </>
          ) : (
            <>
              {(!isDesktop || !totalPrice) && header}

              <VenueItemBody isMobile={isMobile}>
                <LeftSide
                  justifyContent={totalPrice ? 'space-between' : 'flex-end'}
                >
                  {isDesktop ? (
                    <>
                      {!!totalPrice && header}

                      <div>
                        {!isCorporateVenue && (
                          // !isRoomSearch &&
                          <FlexList>
                            {venueTypeText && (
                              <TextValue>{venueTypeText}</TextValue>
                            )}
                            <TextValue>{bookingTypeText}</TextValue>
                          </FlexList>
                        )}

                        <div>
                          <TextValue>
                            {isCorporateVenue && <>Your company office</>}
                            {/* {availableUnitsText} */}
                          </TextValue>

                          <FlexList>
                            {!!totalMeetingRooms && (
                              <TextValue>{meetingRoomsText}</TextValue>
                            )}
                            {!!totalBedrooms && (
                              <TextValue>{bedroomsText}</TextValue>
                            )}
                          </FlexList>
                        </div>
                      </div>
                    </>
                  ) : (
                    <CustomList list={listArray} offsetLeft='14px' />
                  )}
                </LeftSide>

                <RightSide isDesktop={isDesktop}>
                  <AvailabilityTag isVisible={isCorporateVenue && isMobile}>
                    Space available
                  </AvailabilityTag>
                  <NW2PriceBlock
                    isHidden={
                      !totalPrice || isCorporateVenue
                      // || isRoomSearch
                    }
                    currency={currency}
                    totalPrice={totalPrice}
                  />
                  {(((isDesktop || !totalPrice) &&
                    !(
                      (isCorporateVenue || isAlternativesSection) &&
                      isMobile
                    )) ||
                    (!isMobile && isAlternativesSection)) && (
                    <ButtonsContainer flexDirection={'column'}>
                      {buttons}
                    </ButtonsContainer>
                  )}
                </RightSide>
              </VenueItemBody>

              {!isDesktop &&
                !!totalPrice &&
                !isCorporateVenue &&
                !isAlternativesSection && (
                  <ButtonsContainer
                    isInline={isMeetingRoom}
                    isFullWidth={!isMeetingRoom}
                    topOffset={!isMeetingRoom ? offsetXSm : ''}
                  >
                    {buttons}
                  </ButtonsContainer>
                )}

              {isMobile && isAlternativesSection && (
                <ButtonsContainer topOffset={offsetDef} isFullWidth>
                  {buttons}
                </ButtonsContainer>
              )}
            </>
          )}
        </Content>
      </StyledCard>
    </div>
  );
};

export default memo(NW2VenuesListCard);
