import React, {Children, ReactNode} from 'react';
import {Dropdown} from 'antd';

import {
  ListItem,
  ListTitle,
  ListWrapper,
  TriggerItem,
  TriggerItemWrapper,
} from './NW2Dropdown.styles';
import Icon from '../Icon';

export const Placements = [
  'bottomLeft',
  'bottomCenter',
  'bottomRight',
  'topLeft',
  'topCenter',
  'topRight',
] as const;

export const Triggers = ['click', 'hover', 'contextMenu'] as const;

type TPlacement = (typeof Placements)[number];
type TTrigger = (typeof Triggers)[number];

interface IProps {
  trigger?: TTrigger[];
  triggerItem?: ReactNode;
  children: ReactNode;
  placement?: TPlacement;
  triggerItemSize?: string;
  titleItemText?: string;
  maxHeight?: string;
  visible?: boolean;
  open?: boolean;
  onClick?: () => void;
  align?: Record<string, any>;
  onOpenChange?: (open: boolean) => void;
}

function NW2Dropdown({
  triggerItem,
  trigger = [Triggers[0]],
  placement = Placements[0],
  titleItemText = '',
  children,
  triggerItemSize,
  align,
  open,
  ...restProps
}: IProps) {
  const overlay = () => (
    <ListWrapper maxHeight={restProps.maxHeight}>
      {titleItemText && (
        <ListTitle data-testid={'dropdown-list-title'}>
          {titleItemText}
        </ListTitle>
      )}
      {Children.map(children, (child) => (
        <ListItem data-testid={'dropdown-list-item'}>{child}</ListItem>
      ))}
    </ListWrapper>
  );

  return (
    <Dropdown
      dropdownRender={overlay}
      open={open}
      align={align}
      trigger={trigger}
      placement={placement}
      {...restProps}
    >
      <TriggerItemWrapper>
        {triggerItem || (
          <TriggerItem triggerItemSize={triggerItemSize}>
            <Icon icon='MORE_VERTICAL' data-testid={'dropdown-icon'} />
          </TriggerItem>
        )}
      </TriggerItemWrapper>
    </Dropdown>
  );
}

export default NW2Dropdown;
