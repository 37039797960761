import React from 'react';

import TextValue from 'view/components/TextValue';
import NW2VenuesSort from 'view/venue/NW2VenuesList/components/NW2VenuesSort/NW2VenuesSort';

import {useQuery} from 'hooks/useQuery';
import useToggle from 'hooks/useToggle';
import {useAppSelector} from 'store/hooks';

import {StyledSpan} from 'view/components/Typography';
import {ERoomType} from 'types/dto/ERoomType.type';
import {UNIT_TYPE_COPY} from 'constants/venue';

import {
  ListHeaderInner,
  StyledListHeader,
  StyledListHeaderTitleCount,
} from '../../NW2VenuesListComponent.styles';
import {NW2Gray800Color} from 'constants/styleVars';
import {fontSizeXXSm} from 'styles/configs/typography';

type TProps = {
  totalRecords: number;
  loading: boolean;
  isVenueHasPrice: boolean;
};

function PublicVenuesListHeader({
  loading,
  totalRecords,
  isVenueHasPrice,
}: TProps) {
  const {roomType, meetingRoomCapacity, multiRooms} = useQuery();
  const [isVisibleSortMenu, toggleVisibleSortMenu] = useToggle(false);
  const searchCriteria = useAppSelector(({search}) => search.searchCriteria);
  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);

  if (isMobile) return null;

  const totalRecordsFoundText = loading
    ? 'Searching...'
    : `${totalRecords} Venues found`;

  const isFoodAndBeverage = searchCriteria.days?.some(
    (day) => day.foodAndBeverage?.length,
  );

  const isMeetingRoom = roomType === ERoomType.MEETING_ROOM;
  const roomTypeText = UNIT_TYPE_COPY[roomType as ERoomType];

  const multiRoomsText = multiRooms
    ? `${isFoodAndBeverage ? ', food & beverage' : ''}`
    : '';
  const styledListHeaderText = isMeetingRoom
    ? `${roomTypeText.toLowerCase()}${
        multiRooms ? multiRoomsText : ` for ${meetingRoomCapacity} participants`
      }`
    : roomTypeText && roomTypeText.toLowerCase();

  return (
    <StyledListHeader>
      <div>
        <StyledListHeaderTitleCount>
          {totalRecordsFoundText}
        </StyledListHeaderTitleCount>
        <TextValue color={NW2Gray800Color}>
          You’ve searched for: {styledListHeaderText}
        </TextValue>
      </div>
      {!loading && (
        <ListHeaderInner textAlign='right'>
          <NW2VenuesSort
            isVisibleSortMenu={isVisibleSortMenu}
            toggleVisibleSortMenu={toggleVisibleSortMenu}
          />
          {isVenueHasPrice && (
            <StyledSpan fontSize={fontSizeXXSm} NW2Gray600Color>
              Best price / per stay
            </StyledSpan>
          )}
        </ListHeaderInner>
      )}
    </StyledListHeader>
  );
}

export default PublicVenuesListHeader;
