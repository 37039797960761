import React, {useCallback} from 'react';

import SummaryExtrasList from './SummaryExtrasList';
import PriceDisplay from 'view/common/FormatPrice/PriceDisplay';
import TruncateText from 'view/components/TruncateText';
import {BookingUnitCardHeader} from './BookingUnitCardHeader';
import useToggle from 'hooks/useToggle';
import {useAppSelector} from 'store/hooks';
import {VENUE_ROOM_TYPE_MAP} from 'constants/venue';
import {EResourcesCode, EResourcesType} from 'types/dto/IExtras.type';
import {filterPreviewExtrasByExtraType} from 'utils/venueUtils';
import {EBookingSummaryTheme} from 'view/components/NW2SummaryBlock/types';
import BookingUnitCardPackage from './BookingUnitCardPackage';
import BookingUnitCardTimeRangeAndParticipants from './BookingUnitCardTimeRangeAndParticipants';
import {
  ISummaryExtra,
  ISummaryUnit,
  IUnitBooking,
} from 'types/dto/IBooking.types';
import {ERoomType} from 'types/dto/ERoomType.type';
import {
  EPackageText,
  EPackageType,
  TSelectedPackageItem,
} from 'types/dto/IPackages.type';
import {ERoomSchema, ERoomSchemaNames, TBedrooms} from 'types/venue';
import {
  compareExtraWithOrder,
  compareUnitInfoWithOrder,
  getArrowIconByComparing,
  getCurrentUnit,
} from 'view/venue/NW2BookingEdit/helpers';

import {
  ChangeIcon,
  FlexContainer,
  HighlightRow,
  Main,
  MainTitle,
  UnitBodyContainer,
  UnitInfo,
  UnitTitle,
  Wrapper,
} from './NW2BookingUnitCard.styles';
import {IOfferUnitExtra} from 'types/offer';
import {CODE_TO_EXTRA_NAME} from 'constants/extras';
import DateUtils from 'utils/dateUtils';
import {useGroupRequest} from 'view/venue/hooks/useGroupRequest';
import {TSearchCriteriaExtra} from 'types/search';
import {IExtraResponse} from 'types/dto/IPublicVenue';
import {BEDROOMS_NAMES} from 'constants/bedrooms';

const setSummaryExtrasList = (chosenExtras?: ISummaryExtra[]) =>
  chosenExtras?.map((extra) => ({
    accommodationExtraId: extra.accommodationExtraId || extra.extraId,
    totalPrice: extra.price ?? extra.totalPrice,
    calculatedTotalPrice: extra.totalPrice,
    chosenQuantity:
      extra.bookedQuantity ||
      extra.chosenQuantity ||
      (extra as IExtraResponse).quantity,
    name: extra.extraName || extra.name,
    extraType: extra.extraType,
    code: extra.code,
  }));

const setSummaryFoodBeverageOrBedroomsList = (
  extras?: (ISummaryExtra | IOfferUnitExtra | IExtraResponse)[],
  extraType?: EResourcesType,
) => {
  if (!extras) return undefined;
  const isFoodAndBeverage = extraType === EResourcesType.FOOD_AND_BEVERAGE;
  const isBedroom = extraType === EResourcesType.BEDROOM;

  return extras?.map((item) => {
    if (typeof (item as IOfferUnitExtra).totalPrice === 'object') {
      const summaryItem = item as IOfferUnitExtra;

      return {
        id: summaryItem.accommodationExtraId,
        accommodationExtraId: summaryItem.accommodationExtraId,
        totalPrice: summaryItem.totalPrice?.value || summaryItem.price?.value,
        calculatedTotalPrice: summaryItem.totalPrice?.value,
        chosenQuantity: summaryItem.quantity,
        name: CODE_TO_EXTRA_NAME[summaryItem.code as EResourcesCode],
        extraType: isFoodAndBeverage
          ? EResourcesType.FOOD_AND_BEVERAGE
          : EResourcesType.BEDROOM,
        code: summaryItem.code,
      };
    } else {
      const summaryItem = item as ISummaryExtra;

      return {
        accommodationExtraId:
          summaryItem.accommodationExtraId || summaryItem.extraId,
        totalPrice: summaryItem.price || summaryItem.totalPrice,
        calculatedTotalPrice: summaryItem.totalPrice,
        chosenQuantity:
          summaryItem.bookedQuantity ||
          summaryItem.chosenQuantity ||
          (summaryItem as IExtraResponse).quantity ||
          0,
        name:
          isBedroom && summaryItem.code
            ? BEDROOMS_NAMES[summaryItem.code]
            : summaryItem.extraName || summaryItem.name,
        extraType: summaryItem.extraType,
        code: summaryItem.code,
      };
    }
  });
};

interface IProps {
  checkIn: string;
  checkOut: string;
  units: ISummaryUnit[];
  customerPackagesByDay: TSelectedPackageItem[] | null;
  bedrooms?: TBedrooms;
  equipment?: ISummaryExtra[];
  foodBeverage?: (ISummaryExtra | IOfferUnitExtra)[];
  theme?: EBookingSummaryTheme;
  index: number;
  isTimeShowed?: boolean;
  isPriceHidden?: boolean;
  isOffer?: boolean;
  showTimeRangeAndParticipants?: boolean;
  editMode?: boolean;
  isOfferRequest?: boolean;
  totalPriceForPackageSet?: number;
  currency: string;
}

export function NW2BookingUnitCard({
  checkIn,
  checkOut,
  units,
  index,
  foodBeverage,
  isPriceHidden,
  isOffer,
  editMode,
  isTimeShowed = true,
  theme = EBookingSummaryTheme.DARK,
  showTimeRangeAndParticipants = true,
  isOfferRequest,
  bedrooms,
  customerPackagesByDay,
  totalPriceForPackageSet,
  currency,
}: IProps) {
  const {isSingleRegisteredVenue} = useGroupRequest();

  const daysFromOrder = useAppSelector(
    ({customer}) => customer.customerBookingOrder?.orderDays,
  );
  const previousBookingOrder = useAppSelector(
    ({customer}) => customer.customerPreviousBookingOrder,
  );
  const participantsFromSearch = useAppSelector(
    ({search}) => search.searchCriteria.meetingRoomCapacity,
  );

  const initialState = index !== 0;
  const [isCollapsed, onToggle] = useToggle(initialState);

  const foodAndBeverage = setSummaryFoodBeverageOrBedroomsList(
    foodBeverage,
    EResourcesType.FOOD_AND_BEVERAGE,
  );

  const summaryBedroomsList = setSummaryFoodBeverageOrBedroomsList(
    bedrooms,
    EResourcesType.BEDROOM,
  );

  const unitsToCompareWith = previousBookingOrder
    ? previousBookingOrder.orderDays
    : daysFromOrder;

  const getUniInfoChangeState = useCallback(
    (
      unitId: number | undefined,
      unitPrice: number | undefined,
      participants: number | null | undefined,
      unitCheckInDate: string,
      unitCheckOutDate: string,
    ) => {
      const defaultChangeState = {
        unitPriceState: {
          isHighlighted: false,
          arrowIcon: '',
        },
        participantsState: {
          isHighlighted: false,
          arrowIcon: '',
        },
        timeRangeState: {
          isHighlighted: false,
        },
      };

      const currentUnitFromOrder = getCurrentUnit(
        (unitsToCompareWith?.[index].unitBookings || []) as IUnitBooking[],
        unitId,
      );

      if (!editMode || !currentUnitFromOrder) return defaultChangeState;

      const {
        isUnitPriceHighlighted,
        isParticipantsHighlighted,
        isTimeRangeHighlighted,
      } = compareUnitInfoWithOrder(
        unitPrice,
        participants,
        unitCheckInDate,
        unitCheckOutDate,
        currentUnitFromOrder,
      );

      const participantsArrowIcon = getArrowIconByComparing(
        currentUnitFromOrder.participants,
        participants,
      );

      const unitPriceArrowIcon = getArrowIconByComparing(
        currentUnitFromOrder.unitPrice,
        unitPrice,
      );

      return {
        unitPriceState: {
          isHighlighted: isUnitPriceHighlighted,
          arrowIcon: unitPriceArrowIcon,
        },
        participantsState: {
          isHighlighted: isParticipantsHighlighted,
          arrowIcon: participantsArrowIcon,
        },
        timeRangeState: {
          isHighlighted: isTimeRangeHighlighted,
        },
      };
    },
    [editMode, index, unitsToCompareWith],
  );

  const bookedFoodAndBeverage = unitsToCompareWith
    ? unitsToCompareWith[index]?.foodAndBeverage
    : [];

  const bookedBedrooms = unitsToCompareWith
    ? unitsToCompareWith[index]?.bedrooms
    : [];

  const showFoodAndBeverageExtraList = editMode
    ? !!foodAndBeverage?.length || !!bookedFoodAndBeverage?.length
    : !!foodAndBeverage?.length;

  const accommodationData =
    bedrooms ??
    units[0]?.chosenExtras?.filter(
      (item) => item.extraType === EResourcesType.BEDROOM,
    );

  const accommodationExtrasList = accommodationData?.map(
    (item: IOfferUnitExtra | TSearchCriteriaExtra | ISummaryExtra) => {
      if ('bookableWith' in item || 'bookedQuantity' in item) {
        const summaryExtra = item as ISummaryExtra;

        return {
          ...summaryExtra,
          name: summaryExtra.code
            ? CODE_TO_EXTRA_NAME[summaryExtra.code]
            : summaryExtra.name,
          totalPrice: summaryExtra.totalPrice || 0,
        };
      }

      const offerSummaryExtra = item as IOfferUnitExtra;

      return {
        ...offerSummaryExtra,
        name: CODE_TO_EXTRA_NAME[offerSummaryExtra.code as EResourcesCode],
        totalPrice:
          offerSummaryExtra.totalPrice?.value || offerSummaryExtra.totalPrice,
        chosenQuantity: offerSummaryExtra.quantity,
      };
    },
  );

  const showBedroomsList = editMode
    ? !!summaryBedroomsList?.length || !!bookedBedrooms?.length
    : !!accommodationExtrasList?.length;

  const checkIsDayDataChanged = () => {
    const isFoodAndBeverageChanged = foodAndBeverage?.some(
      ({code, chosenQuantity, calculatedTotalPrice}) =>
        compareExtraWithOrder(
          code,
          chosenQuantity,
          calculatedTotalPrice,
          bookedFoodAndBeverage,
          foodAndBeverage,
          true,
        ).isHighlighted,
    );

    if (isFoodAndBeverageChanged) return true;

    const isBedroomsChanged = summaryBedroomsList?.some(
      ({code, chosenQuantity, calculatedTotalPrice}) =>
        compareExtraWithOrder(
          code,
          chosenQuantity,
          calculatedTotalPrice,
          bookedBedrooms,
          summaryBedroomsList,
          true,
        ).isHighlighted,
    );

    if (isBedroomsChanged) return true;

    return units.some(
      ({
        unitId,
        unitPrice,
        participants,
        checkInDate: unitCheckInDate,
        checkOutDate: unitCheckOutDate,
        chosenExtras,
      }) => {
        const currentUnitFromOrder = getCurrentUnit(
          unitsToCompareWith?.[index].unitBookings as IUnitBooking[],
          unitId,
        );

        if (!currentUnitFromOrder) return false;

        const {
          isUnitPriceHighlighted,
          isParticipantsHighlighted,
          isTimeRangeHighlighted,
        } = compareUnitInfoWithOrder(
          unitPrice,
          participants,
          unitCheckInDate,
          unitCheckOutDate,
          currentUnitFromOrder,
        );

        if (
          isUnitPriceHighlighted ||
          isParticipantsHighlighted ||
          isTimeRangeHighlighted
        )
          return true;

        const bookedEquipment = filterPreviewExtrasByExtraType<ISummaryExtra>(
          currentUnitFromOrder.bookedExtras,
          EResourcesType.EQUIPMENT,
        );

        const extras = setSummaryExtrasList(chosenExtras);

        const equipment = filterPreviewExtrasByExtraType<ISummaryExtra>(
          extras,
          EResourcesType.EQUIPMENT,
        );

        if (equipment?.length !== bookedEquipment.length) return true;

        return equipment?.some(
          ({code, chosenQuantity, calculatedTotalPrice}) =>
            compareExtraWithOrder(
              code,
              chosenQuantity,
              calculatedTotalPrice,
              bookedEquipment,
              equipment,
              true,
            ).isHighlighted,
        );
      },
    );
  };

  const isHeaderHighlighted = editMode ? checkIsDayDataChanged() : false;
  const timeStart = DateUtils.findMinMaxTime(units, 'checkInDate', false);
  const timeEnd = DateUtils.findMinMaxTime(units, 'checkOutDate', true);

  return (
    <Wrapper colorTheme={theme}>
      <BookingUnitCardHeader
        checkIn={timeStart || checkIn}
        checkOut={timeEnd || checkOut}
        theme={theme}
        isCollapsed={isCollapsed}
        onToggle={onToggle}
        isTimeShowed={isTimeShowed}
        isHighlighted={isHeaderHighlighted}
      />

      <Main isCollapsed={isCollapsed} colorTheme={theme}>
        {units.map((unit, unitIndex) => {
          const {
            name,
            totalSearchPrice,
            chosenExtras,
            unitType,
            type,
            unitBookingId,
            unitId,
            unitInfo,
            unitPrice,
            participants,
            checkInDate: unitCheckInDate,
            checkOutDate: unitCheckOutDate,
            chosenPackage: customerBookedPackage,
            packagePrice: packagePriceFromOrder,
            unitFilter,
          } = unit;

          const currentUnitFromOrder =
            unitsToCompareWith &&
            (unitsToCompareWith[index]?.unitBookings as IUnitBooking[])?.find(
              (comparableUnit) => comparableUnit.unitId === unitId,
            );

          const bookedExtras = currentUnitFromOrder?.bookedExtras;

          const extras = setSummaryExtrasList(chosenExtras);

          const equipment = filterPreviewExtrasByExtraType<ISummaryExtra>(
            extras,
            EResourcesType.EQUIPMENT,
          );

          const bookedEquipment = filterPreviewExtrasByExtraType<ISummaryExtra>(
            bookedExtras,
            EResourcesType.EQUIPMENT,
          );

          const showEquipmentExtraList = editMode
            ? !!equipment?.length || !!bookedEquipment?.length
            : !!equipment?.length;

          const spaceName = `${
            ERoomSchema[unit.chosenSetupStyle as ERoomSchemaNames] || ''
          } Meeting Room`;

          const unitName =
            isSingleRegisteredVenue || isOffer || unitBookingId
              ? name || unitInfo?.unitName || ''
              : spaceName;

          const price = totalSearchPrice || unitPrice;

          const isMeetingRoom = unitInfo?.roomType === ERoomType.MEETING_ROOM;

          const {unitPriceState, participantsState, timeRangeState} =
            getUniInfoChangeState(
              unitId,
              unitPrice,
              participants,
              unitCheckInDate,
              unitCheckOutDate,
            );

          const selectedPackage =
            customerBookedPackage ||
            customerPackagesByDay?.find(([uId]) => unitId === uId)?.[1];

          const isPackageInOrder = !!customerBookedPackage;

          const showUnitPrice = !isPriceHidden && unitId;

          return (
            <UnitBodyContainer key={unitBookingId || `${unitId}${index}`}>
              {unitId && (
                <MainTitle>
                  {selectedPackage && !editMode
                    ? `Package: ${
                        EPackageText[
                          (selectedPackage?.type as EPackageType) ||
                            customerBookedPackage?.type
                        ]
                      }`
                    : `${
                        VENUE_ROOM_TYPE_MAP[(unitType as ERoomType) || type]
                      } ${units.length === 1 ? '' : unitIndex + 1}${
                        customerBookedPackage ? ' (with package)' : ''
                      }`}
                </MainTitle>
              )}

              {selectedPackage || isPackageInOrder ? (
                <BookingUnitCardPackage
                  selectedPackage={selectedPackage}
                  participants={
                    customerBookedPackage
                      ? participants || 1
                      : unitFilter?.capacity || participantsFromSearch
                  }
                  unitName={unitName}
                  unitCheckInDate={unitCheckInDate}
                  unitCheckOutDate={unitCheckOutDate}
                  isPackageInOrder={isPackageInOrder}
                  price={selectedPackage?.price || packagePriceFromOrder}
                  totalPriceForPackageSet={totalPriceForPackageSet}
                  editMode={editMode}
                  orderUnit={currentUnitFromOrder}
                  theme={theme}
                  participantsState={participantsState}
                  timeRangeState={timeRangeState}
                />
              ) : (
                <>
                  <HighlightRow
                    colorTheme={theme}
                    isHighlighted={unitPriceState.isHighlighted}
                    marginBottom={4}
                  >
                    <UnitTitle>
                      {unitId && <TruncateText text={unitName} hideToggler />}
                      {showUnitPrice && (
                        <FlexContainer>
                          <PriceDisplay price={price} currency={currency} />
                          {unitPriceState.isHighlighted && (
                            <ChangeIcon
                              icon={unitPriceState.arrowIcon}
                              iconColor={false}
                              marginLeft={6}
                            />
                          )}
                        </FlexContainer>
                      )}
                    </UnitTitle>
                  </HighlightRow>
                  {showTimeRangeAndParticipants && (
                    <BookingUnitCardTimeRangeAndParticipants
                      theme={theme}
                      participantsState={participantsState}
                      participants={participants}
                      isMeetingRoom={isMeetingRoom}
                      timeRangeState={timeRangeState}
                      unitCheckInDate={unitCheckInDate}
                      unitCheckOutDate={unitCheckOutDate}
                    />
                  )}
                  <UnitInfo colorTheme={theme} />
                </>
              )}
              {showEquipmentExtraList && (
                <SummaryExtrasList
                  extrasList={equipment}
                  bookedExtras={bookedEquipment}
                  currency={currency}
                  isPriceHidden={isPriceHidden}
                  editMode={editMode}
                  colorTheme={theme}
                  isConfirmationPage={!!daysFromOrder?.length}
                />
              )}
            </UnitBodyContainer>
          );
        })}

        {showFoodAndBeverageExtraList && (
          <SummaryExtrasList
            extrasList={foodAndBeverage || []}
            bookedExtras={bookedFoodAndBeverage}
            currency={currency}
            isPriceHidden={isPriceHidden}
            editMode={editMode}
            colorTheme={theme}
            isConfirmationPage={!!daysFromOrder?.length}
          />
        )}

        {showBedroomsList && (
          <SummaryExtrasList
            extrasList={
              (isOfferRequest
                ? (accommodationExtrasList as ISummaryExtra[])
                : summaryBedroomsList) || []
            }
            bookedExtras={bookedBedrooms}
            title='Accommodation'
            currency={currency}
            editMode={editMode}
            colorTheme={theme}
            isPriceHidden={isOfferRequest || isPriceHidden}
            isConfirmationPage={!!daysFromOrder?.length}
          />
        )}
      </Main>
    </Wrapper>
  );
}
