import React, {FC, SyntheticEvent, useState} from 'react';
import {useAppDispatch, useAppSelector} from 'store/hooks';

import Icon from 'view/components/Icon';
import NW2Dropdown from 'view/components/NW2Dropdown';
import StyledAntdDrawer from 'view/common/StyledAntdDrawer';
import SortTextPopover from './components/SortTextPopover';

import {
  StyledSort,
  SortButtonContent,
  SortDropdownItem,
  SortTitle,
  MobileHeaderTitleWrap,
  MobileHeaderTitle,
  StyledDiv,
  PopoverGlobalStyle,
  StyledCaretIcon,
} from './NW2VenuesSort.styles';
import {StyledButtonFilter} from '../NW2VenuesFilter/NW2VenuesFilter.styles';
import {offset36} from 'constants/styleVars';
import {setVenuesSortOption} from 'store/filters/filtersSlice';
import {ESortOption, sortOptions} from './constants';
import useToggle from 'hooks/useToggle';

type TProps = {
  toggleVisibleSortMenu: () => void;
  isVisibleSortMenu: boolean;
};

const NW2VenuesSort: FC<TProps> = ({
  toggleVisibleSortMenu,
  isVisibleSortMenu,
}) => {
  const isMobile = useAppSelector(({app}) => app.deviceType.isMobile);
  const isDesktop = useAppSelector(({app}) => app.deviceType.isDesktop);
  const storedSortOption = useAppSelector(
    ({filters}) => filters.venuesSortOption,
  );
  const dispatch = useAppDispatch();

  const [selectedSort, setSelectedSort] = useState(storedSortOption);
  const [isPopoverVisible, togglePopoverVisibility] = useToggle(false);

  const handleSortSelection = (sortOption: ESortOption) => () => {
    setSelectedSort(sortOption);
    dispatch(setVenuesSortOption(sortOption));
    toggleVisibleSortMenu();
  };
  const handleIconClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    togglePopoverVisibility();
  };

  const handleDropdownVisibility = (isOpen: boolean) => {
    if (isVisibleSortMenu !== isOpen) {
      toggleVisibleSortMenu();
    }
  };

  const dropdownItems = sortOptions.map(
    ({value, label}: {value: ESortOption; label: string}) => (
      <SortDropdownItem
        key={value}
        isActive={selectedSort === value}
        onClick={handleSortSelection(value)}
      >
        {selectedSort === value && <Icon icon='CHECK' />}
        {label}
        {isMobile && value === ESortOption.RECOMMENDED && (
          <StyledDiv onClick={handleIconClick}>
            <Icon icon='INFORMATION_CIRCLE' />
          </StyledDiv>
        )}
      </SortDropdownItem>
    ),
  );

  const triggerItem = (
    <StyledButtonFilter
      buttonType='chip'
      size='small'
      icon={<StyledCaretIcon icon='CARET' isOpen={isVisibleSortMenu} />}
      iconPlace='right'
      isActive={isVisibleSortMenu}
    >
      <SortButtonContent>
        {selectedSort}
        {selectedSort === ESortOption.RECOMMENDED && (
          <SortTextPopover
            isPopoverVisible={isPopoverVisible}
            togglePopoverVisibility={togglePopoverVisibility}
          />
        )}
      </SortButtonContent>
    </StyledButtonFilter>
  );

  return (
    <StyledSort>
      {isDesktop && <SortTitle>Sort by:</SortTitle>}
      {isMobile ? (
        <>
          <StyledAntdDrawer
            onClose={toggleVisibleSortMenu}
            open={isVisibleSortMenu}
            title={
              <MobileHeaderTitleWrap>
                <Icon icon='DATA_TRANSFER_VERTICAL' />
                <MobileHeaderTitle>Sort by</MobileHeaderTitle>
              </MobileHeaderTitleWrap>
            }
            height='auto'
            bodyStyle={{padding: 0}}
          >
            {dropdownItems}
          </StyledAntdDrawer>
          <SortTextPopover
            isPopoverVisible={isPopoverVisible}
            togglePopoverVisibility={togglePopoverVisibility}
          />
        </>
      ) : (
        <NW2Dropdown
          triggerItemSize={offset36}
          open={isVisibleSortMenu}
          triggerItem={triggerItem}
          onOpenChange={handleDropdownVisibility}
        >
          {dropdownItems}
        </NW2Dropdown>
      )}
      <PopoverGlobalStyle />
    </StyledSort>
  );
};
export default NW2VenuesSort;
