import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {IRecordDTO, ISupplierMasterDataDTO, Pagination} from 'types/agent';
import {
  TRecordFormFields,
  TSupplierMasterDataFormFields,
} from 'view/agent/AgentView/types';

interface IAgentReducer {
  isRecordLoading: boolean;
  record: IRecordDTO[];
  recordFormValues: TRecordFormFields;
  supplierMasterData: ISupplierMasterDataDTO[];
  supplierMasterDataFormValues: TSupplierMasterDataFormFields;
  isSupplierMasterDataLoading: boolean;
  isUpdateVenueContactLoading: boolean;
}

const initialState: IAgentReducer = {
  isRecordLoading: false,
  record: [],
  recordFormValues: {},
  supplierMasterDataFormValues: {},
  isSupplierMasterDataLoading: false,
  supplierMasterData: [],
  isUpdateVenueContactLoading: false,
};

export const agentSlice = createSlice({
  name: 'agent',
  initialState,
  reducers: {
    setRecordFormValues(state, action: PayloadAction<TRecordFormFields>) {
      state.recordFormValues = action.payload;
    },
    setSupplierMasterDataFormValues(
      state,
      action: PayloadAction<TSupplierMasterDataFormFields>,
    ) {
      state.supplierMasterDataFormValues = action.payload;
    },
    setRecordLoading(state, action: PayloadAction<boolean>) {
      state.isRecordLoading = action.payload;
    },
    setRecord(state, action: PayloadAction<IRecordDTO[]>) {
      state.record = action.payload;
      state.isRecordLoading = false;
    },
    setSupplierMasterDataLoading(state, action: PayloadAction<boolean>) {
      state.isSupplierMasterDataLoading = action.payload;
    },
    setSupplierMasterData(
      state,
      action: PayloadAction<Pagination<ISupplierMasterDataDTO>>,
    ) {
      state.supplierMasterData = action.payload.content;
      state.isSupplierMasterDataLoading = false;
    },
    setUpdateVenueContactLoading(state, action: PayloadAction<boolean>) {
      state.isUpdateVenueContactLoading = action.payload;
    },
  },
});

export const {
  setRecord,
  setRecordLoading,
  setRecordFormValues,
  setSupplierMasterData,
  setSupplierMasterDataFormValues,
  setSupplierMasterDataLoading,
  setUpdateVenueContactLoading,
} = agentSlice.actions;

export default agentSlice.reducer;
