import styled, {createGlobalStyle} from 'styled-components';
import Icon from 'view/components/Icon';

import {
  blackColor,
  borderRadiusLg,
  fontSizeSm,
  fontSizeXSm,
  fontWeightBold,
  fontWeightExtraBold,
  lineHeightSm,
  NW2Blue500Color,
  offsetDef,
  offsetSm,
  offsetXSm,
  offsetXXSm,
  whiteColor,
} from 'constants/styleVars';

export const StyledDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledSort = styled.div`
  display: flex;
  gap: ${offsetDef};
  align-items: center;
  margin-bottom: ${offsetXSm};
`;
export const SortTitle = styled.div`
  font-size: ${fontSizeXSm};
  font-weight: ${fontWeightBold};
  line-height: ${lineHeightSm};
`;
export const SortButtonContent = styled.div`
  display: flex;
  align-items: center;
  gap: ${offsetXXSm};
`;

export const SortPopoverContent = styled.div<{isMobile: boolean}>`
  color: ${({isMobile}) => (isMobile ? blackColor : whiteColor)};
  background: ${({isMobile}) => (isMobile ? whiteColor : blackColor)};
  font-size: ${({isMobile}) => (isMobile ? fontSizeSm : fontSizeXSm)};
`;

export const SortDropdownItem = styled.div<{isActive: boolean}>`
  background: ${({isActive}) => isActive && NW2Blue500Color};
  padding: ${offsetDef} ${offsetSm};
  display: flex;
  gap: ${offsetXSm};
  align-items: center;
`;

export const MobileHeaderTitleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${offsetXSm};
`;

export const MobileHeaderTitle = styled.div`
  font-size: ${fontSizeXSm};
  font-weight: ${fontWeightExtraBold};
  line-height: ${lineHeightSm};
  text-transform: uppercase;
`;

export const PopoverGlobalStyle = createGlobalStyle`
    .sorting-popover {
        .ant-popover {
            &-content {
                transform: translateY(12px);
            }

            &-arrow-content {
                &::before {
                    background-color: ${blackColor};
                }
            }

            &-inner {
                border-radius: ${borderRadiusLg};
                background-color: ${blackColor};
                overflow: hidden;
            }

            &-inner-content {
                padding: ${offsetXSm};
                background-color: ${blackColor};
            }
        }
    }
`;
export const StyledCaretIcon = styled(Icon)<{isOpen: boolean}>`
  transform: ${({isOpen}) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s ease;
`;
