import React, {CSSProperties, ReactNode} from 'react';
import {Popover} from 'antd';
import {AbstractTooltipProps} from 'antd/lib/tooltip';

import Icon from 'view/components/Icon';

import {
  DrawerTitle,
  PopoverTitle,
  StyledDrawer,
  StyledPopoverTitleWrapper,
  PopoverGlobalStyle,
} from './NW2PopoverDrawer.styles';
import {modalSmallWidth} from 'constants/styleVars';

interface IProps extends Partial<AbstractTooltipProps> {
  header?: ReactNode;
  body: ReactNode;
  drawerFooter?: ReactNode | null;
  popoverBodyStyle?: CSSProperties;
  overlayInnerStyle?: CSSProperties;
  popoverWidth?: string;
  drawerHeight?: string;
  isMobile: boolean;
  clickable?: boolean;
  overlayClassName?: string;
  onClose?: () => void;
  handleVisibleChange?: (val: boolean) => void;
  customGlobalStyle?: ReactNode;
  drawerBodyStyle?: CSSProperties;
}

function NW2PopoverDrawer({
  header,
  body,
  placement,
  drawerFooter = null,
  popoverBodyStyle,
  overlayInnerStyle,
  popoverWidth,
  drawerHeight,
  isMobile,
  clickable,
  open,
  onClose,
  handleVisibleChange,
  overlayClassName,
  children,
  customGlobalStyle,
  drawerBodyStyle,
}: IProps) {
  const popoverTrigger = clickable ? 'click' : 'hover';

  const popoverTitle = () => (
    <StyledPopoverTitleWrapper>
      <PopoverTitle>{header}</PopoverTitle>
      {clickable && <Icon icon='NW2_CLOSE' onClick={onClose} />}
    </StyledPopoverTitleWrapper>
  );

  const popoverStyle = {
    ...popoverBodyStyle,
    width: popoverWidth || modalSmallWidth,
  };

  return (
    <>
      {isMobile ? (
        <>
          <StyledDrawer
            title={<DrawerTitle>{header}</DrawerTitle>}
            footer={drawerFooter}
            onClose={onClose}
            open={open}
            height={drawerHeight || 'auto'}
            closeIcon={<Icon icon='NW2_CLOSE' />}
            placement='bottom'
            destroyOnClose
            bodyStyle={drawerBodyStyle}
          >
            {body}
          </StyledDrawer>
          {children}
        </>
      ) : (
        <Popover
          title={header ? popoverTitle : null}
          content={body}
          trigger={popoverTrigger}
          open={open}
          onOpenChange={handleVisibleChange}
          overlayStyle={popoverStyle}
          overlayInnerStyle={overlayInnerStyle}
          placement={placement}
          overlayClassName={overlayClassName || 'custom-popover'}
        >
          {children}
        </Popover>
      )}

      {customGlobalStyle || <PopoverGlobalStyle />}
    </>
  );
}

export default NW2PopoverDrawer;
