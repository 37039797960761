import React, {ReactNode} from 'react';
import {useSelector} from 'react-redux';
import _get from 'lodash/get';

import NW2Button from 'view/components/NW2Button';

import {pluralize} from 'utils/helpers';
import {filterSpacesByRoomType} from '../../helpers/NMMSpaceHelper';

import {ERoomType} from 'types/dto/ERoomType.type';
import {ISpacesData, TVenueBedroomsResponse} from 'types/venue';
import {
  Text,
  Title,
} from 'view/venue/NW2VenueProfile/components/Header/Header.styles';
import {EAccommodationType} from 'types/dto/IPublicVenue';
import {offsetXLg} from 'constants/styleVars';
import {EUserRoleCognito} from 'types/dto/EUserRoleCognito';

const GRID_WIDTH = '210px';

type TReturnProps = {
  title: string | undefined;
  subtitle?: ReactNode | string;
  items: string | number;
  content: ReactNode;
  gridWidth?: string;
  gap?: string;
};

const titleName: {[key in ERoomType]?: string} = {
  [ERoomType.MEETING_ROOM]: 'Meeting rooms',
  [ERoomType.WORK_SPACE]: 'Work desks',
  [ERoomType.BEDROOM]: 'Bedrooms',
};

interface IProps {
  spaces: ISpacesData[];
  accommodationType: EAccommodationType;
  handleCreateRoom?: () => void;
  roomType: ERoomType;
}
export const useSpaceHeader = ({
  spaces,
  accommodationType,
  handleCreateRoom,
  roomType,
}: IProps): TReturnProps => {
  const isExternalVenue = accommodationType === EAccommodationType.VENUE;
  const filteredSpaces = filterSpacesByRoomType(spaces, roomType);

  // TODO: Find better solution
  const isAgent =
    useSelector(({app}) => app.user.role) === EUserRoleCognito.ROLE_AGENT;
  const bedrooms: TVenueBedroomsResponse = useSelector((state) =>
    _get(state, 'venue.bedrooms'),
  );
  const isBedroomsLoading: boolean = useSelector((state) =>
    _get(state, 'venue.bedroomsLoading'),
  );

  const isBedroom = roomType === ERoomType.BEDROOM;

  const numberOfSpaces = filteredSpaces.length;

  const disabledSpaces = 0;
  const showLive = numberOfSpaces > 0;
  const showDisabled =
    numberOfSpaces !== 0 && disabledSpaces !== 0 && isExternalVenue;

  const totalText = isBedroom ? 'Total bedrooms' : 'Total created';

  return {
    title: titleName[roomType],
    items: 5,
    gridWidth: GRID_WIDTH,
    gap: offsetXLg,
    content: (
      <>
        <div data-testid='space-total-count'>
          <Title>{totalText}</Title>
          <Text>
            {isBedroom
              ? isBedroomsLoading
                ? 'Loading...'
                : bedrooms?.total
              : pluralize('space', numberOfSpaces)}
          </Text>
        </div>

        {showLive ? (
          <div data-testid='space-status'>
            <Title>Live</Title>
            <Text>{pluralize('space', numberOfSpaces)}</Text>
          </div>
        ) : (
          <div />
        )}

        {showDisabled ? (
          <div data-testid='space-disabled'>
            <Title>Disabled</Title>
            <Text>{pluralize('space', disabledSpaces)}</Text>
          </div>
        ) : (
          <div />
        )}
        {/* Need for structure */}
        <div />

        <div>
          {!isAgent && !isBedroom && (
            <NW2Button
              buttonType='primary'
              onClick={handleCreateRoom}
              size='small'
              data-testid='header create meeting room'
            >
              Create new
            </NW2Button>
          )}
        </div>
      </>
    ),
  };
};
