import React, {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import queryString from 'query-string';
import _get from 'lodash/get';

import NW2VenuesListCard from 'view/venue/NW2VenuesList/components/NW2VenuesListCard';
import NW2NoFoundBlock from 'view/components/NW2NoFoundBlock/NW2NoFoundBlock';
import PublicVenuesListHeader from './components/PublicVenuesListHeader';
import useSearchData from 'view/venue/hooks/search/useSearchData';
import NoFoundBlockListItems from './components/NoFoundBlockListItems';
import useFlutterWebView from 'utils/bridgeBTC/useFlutterWebView';
import PaginationBlock from './components/PaginationBlock';
import NW2Button from 'view/components/NW2Button';
import SkeletonPreloaderList from './components/SkeletonPreloaderList/SkeletonPreloaderList';
import RequestForm from 'view/customer/RequestForm/RequestForm';

import {useAppSelector} from 'store/hooks';
import {EAccommodationType} from 'types/dto/IPublicVenue';
import {EFrontendErrors} from 'types/errors';
import {setHeaderCollapsed} from 'store/app/appSlice';
import {setSearchFocusedInput} from 'store/search/searchSlice';
import {ERoomType} from 'types/dto/ERoomType.type';
import {LOCATION_ID, VENUE_LIST_ITEMS_PER_PAGE} from 'constants/app';
import {
  NoFoundBlockWrapper,
  StyledButton,
  VenuesGridContainer,
  VenuesSection,
} from './NW2VenuesListComponent.styles';
import {useShortList} from 'view/venue/Offer/hooks/useShortList';
import {IMergedVenue} from 'types/search';
import {scrollTo} from 'utils/scrollTo';
import useToggle from 'hooks/useToggle';

type TProps = {
  activeMarkerId: number;
  totalRecords: number;
  venuesList: IMergedVenue[];
  getVenuesWithPagination: () => void;
  onRequestForOfferClick: (venue: IMergedVenue) => () => void;
  isLoading: boolean;
};
const NW2VenuesListComponent = ({
  activeMarkerId,
  venuesList,
  totalRecords,
  getVenuesWithPagination,
  onRequestForOfferClick,
  isLoading,
}: TProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {isBTCApp, postToFlutterApp} = useFlutterWebView();
  const {isMultiRequest} = useShortList();
  const [isRequestFormActive, onToggleOrderDetails] = useToggle(false);

  const error: unknown = useSelector((state) =>
    _get(state, 'venuesReducer.error'),
  );
  const isCorporateAccount = useAppSelector(({app}) => app.corporateAccountId);
  const roomType = useAppSelector(({search}) => search.searchCriteria.roomType);
  const isVenueHasPrice = venuesList.some((venue) => venue.totalPrice);

  const {querySearchData} = useSearchData();

  const {hasInternalVenues, hasExternalVenues} = useMemo(
    () => ({
      hasInternalVenues: venuesList.some(
        (venue: IMergedVenue) =>
          venue.accommodationType === EAccommodationType.CORPORATE_OFFICE,
      ),
      hasExternalVenues: venuesList.some(
        (venue: IMergedVenue) =>
          venue.accommodationType === EAccommodationType.VENUE,
      ),
    }),
    [venuesList],
  );

  const viewDetails = (venueId: number) => {
    const additionalParams = isMultiRequest ? '&isMultiRequest=true' : '';

    const stringSearch = `${queryString.stringify(
      querySearchData,
    )}${additionalParams}`;

    navigate({
      pathname: `/venue/${venueId}/details`,
      search: stringSearch,
    });
  };

  const getVenuesByAccommodationType = (
    venues: IMergedVenue[],
    accommodationType: EAccommodationType,
  ) => {
    const commonProps = {
      activeMarkerId,
      viewDetails,
      roomType: querySearchData['roomType'] as ERoomType,
      isLoading,
      onRequestForOfferClick,
    };

    return venues
      .filter((venue) => venue.accommodationType === accommodationType)
      .map((venue) => (
        <NW2VenuesListCard
          key={venue.accommodationId || venue.id}
          venue={venue}
          {...commonProps}
        />
      ));
  };

  const showNoAccessToVenue =
    error === EFrontendErrors.ACCESS_TO_VENUE_DENIED_ERROR;

  const startNewSearchHandler = () => {
    if (isBTCApp) return postToFlutterApp();
    dispatch(setSearchFocusedInput(LOCATION_ID));
    dispatch(setHeaderCollapsed(false));
    const locationInput = document.getElementById(LOCATION_ID);
    locationInput?.focus();
  };

  const hasNoExternalVenues = !hasExternalVenues && !isCorporateAccount;
  const hasNoInternalVenues = !hasInternalVenues && isCorporateAccount;

  const onModifySearchCriteria = () => {
    scrollTo(0, () => {
      dispatch(setHeaderCollapsed(false));
    });
  };

  const isWorkSpace = roomType === ERoomType.WORK_SPACE;

  const isPaginationVisible =
    totalRecords > VENUE_LIST_ITEMS_PER_PAGE &&
    venuesList.length !== totalRecords;

  const noFoundMeetingRoomButtons = (
    <>
      <NW2Button onClick={onToggleOrderDetails} size='small'>
        Request customer support
      </NW2Button>
      <NW2Button onClick={onModifySearchCriteria} size='small'>
        Modify search criteria
      </NW2Button>
    </>
  );

  return (
    <>
      {/* Corporate user that has no access to some venue details is navigated here from VenueDetails page */}
      {showNoAccessToVenue && (
        <NoFoundBlockWrapper>
          <NW2NoFoundBlock
            title='No access'
            description='Your account does not have access to view this office location.'
            buttons={
              <StyledButton
                buttonType='secondary'
                inline
                onClick={startNewSearchHandler}
                size='small'
              >
                Start new search
              </StyledButton>
            }
          />
        </NoFoundBlockWrapper>
      )}

      {/* No public venues card  */}
      {!isLoading &&
        !venuesList.length &&
        (hasNoExternalVenues || hasNoInternalVenues) && (
          <NoFoundBlockWrapper noBorder>
            <NW2NoFoundBlock
              title='No Venues found'
              description={
                !isWorkSpace
                  ? `Unfortunately there are no options for your search criteria in this destination.
                    Please go to the customer support request template and our support agents will give their best to provide
                    you with options including the offers from the venues or modify your search criteria.`
                  : 'You searched for a work desk. Why not try one of the following:'
              }
              listItems={
                isWorkSpace && (
                  <NoFoundBlockListItems
                    roomType={roomType}
                    startNewSearchHandler={startNewSearchHandler}
                  />
                )
              }
              buttons={!isWorkSpace && noFoundMeetingRoomButtons}
            />
          </NoFoundBlockWrapper>
        )}
      {/* TODO:Comment this code for some time. Till real users will appear.*/}
      {/* Sign in suggestion card */}
      {/*{!isUserLoggedIn && (*/}
      {/*  <IntroSection>*/}
      {/*    <StyledActionCardClosable*/}
      {/*      title='Don’t see your own office?'*/}
      {/*      description='If your company is a registered NewWork client, to view office venue'*/}
      {/*      buttonText='Log in'*/}
      {/*      buttonPosition='postfix'*/}
      {/*      icon='WHITE_HOUSE'*/}
      {/*      iconProps={ACTION_CARD_ICON_PROPS}*/}
      {/*      onClick={signIn}*/}
      {/*    />*/}
      {/*  </IntroSection>*/}
      {/*)}*/}

      {(!!totalRecords || isLoading) && (
        <PublicVenuesListHeader
          loading={isLoading}
          totalRecords={totalRecords}
          isVenueHasPrice={isVenueHasPrice}
        />
      )}

      {/* offices */}
      {isCorporateAccount && hasInternalVenues && (
        <VenuesSection>
          <VenuesGridContainer>
            {getVenuesByAccommodationType(
              venuesList,
              EAccommodationType.CORPORATE_OFFICE,
            )}
          </VenuesGridContainer>
        </VenuesSection>
      )}

      {/* Public venues */}
      {hasExternalVenues && (
        <>
          <VenuesSection>
            {/* Sort and total header */}

            <VenuesGridContainer isPublicVenue>
              {getVenuesByAccommodationType(
                venuesList,
                EAccommodationType.VENUE,
              )}
            </VenuesGridContainer>
          </VenuesSection>
          <PaginationBlock
            isVisible={isPaginationVisible}
            getItemsWithPagination={getVenuesWithPagination}
          />
        </>
      )}

      {!isLoading && !isWorkSpace && !!totalRecords && !isPaginationVisible && (
        <NoFoundBlockWrapper noBorder>
          <NW2NoFoundBlock
            title='No more results'
            description={`Unfortunately there are no more options for your search criteria in this destination.
             If you don’t find suitable options please go to the customer support request template and our support
              agents will give their best to provide you with a few more options including the offers from the venues
               or modify your search criteria.`}
            buttons={noFoundMeetingRoomButtons}
          />
        </NoFoundBlockWrapper>
      )}

      {/*Show skeleton on init, before venues list fetch*/}
      {isLoading && !venuesList.length && (
        <SkeletonPreloaderList isPublicVenue={!isCorporateAccount} />
      )}

      {isRequestFormActive && (
        <RequestForm onCloseOrderDetails={onToggleOrderDetails} />
      )}
    </>
  );
};

export default NW2VenuesListComponent;
