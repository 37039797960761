import styled from 'styled-components';

import Icon from 'view/components/Icon';
import NW2Button from 'view/components/NW2Button/NW2Button';

import {
  borderRadiusLg,
  fontSizeXXSm,
  fontWeightBold,
  headerZIndex,
  lineHeightXSm,
  mdBp,
  NW2Blue50Color,
  NW2Blue800Color,
  NW2Gray200Color,
  NW2Primary,
  offsetDef,
  offsetXSm,
  offsetXXLg,
  offsetXXSm,
  whiteColor,
} from 'constants/styleVars';

export const StyledWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${offsetDef};
  padding: ${offsetDef} ${offsetXXLg};
  align-items: center;

  @media (min-width: ${mdBp}px) {
    position: sticky;
    top: 0;
    background-color: ${whiteColor};
    z-index: ${headerZIndex};
  }
`;

export const StyledIcon = styled(Icon)``;

export const StyledButtonFilter = styled(NW2Button)<{isActive?: boolean}>`
  font-size: ${fontSizeXXSm};
  line-height: ${lineHeightXSm};
  padding: ${offsetXXSm} ${offsetDef} ${offsetXXSm} ${offsetXSm};
  text-transform: none;
  font-weight: ${fontWeightBold};
  background-color: ${({isActive}) => isActive && `${NW2Blue50Color}`};
  color: ${({isActive}) => isActive && `${NW2Blue800Color}`};
  border-color: ${({isActive}) => (isActive ? NW2Primary : '#a2a9b0')};

  ${StyledIcon} {
    color: currentColor;
    margin-right: -${offsetXXSm};
  }
`;

export const StyledButtonFilterDropdown = styled(StyledButtonFilter)``;

export const StyledDivider = styled.div`
  width: 2px;
  height: 26px;
  background-color: ${NW2Gray200Color};
  border-radius: ${borderRadiusLg};
`;
