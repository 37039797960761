import {ERoomType} from './dto/ERoomType.type';
import {EAccommodationType, EVenueType} from './dto/IPublicVenue';
import {ITimeData} from './dto/ISearch.types';
import {
  EResourcesCode,
  EResourcesType,
  IExtrasOption,
} from './dto/IExtras.type';
import {
  EEventType,
  ERoomSchemaNames,
  IPointOfInterest,
  TVenueType,
} from './venue';
import {ILocation} from './dto/ILocation.types';
import {
  TBedroomsCatering,
  THandleSetBedroomsCatering,
} from '../view/components/NW2SearchSection/components/ExtendedMeetingRoomsPopup/components/AddAccommodationRequest/types';

export type TVenuesQuerySearchData = {
  searchString: string;
  latitude: string;
  longitude: string;
  radius?: number;
  zoom?: number;
  page?: number;
  seatingStyle?: ERoomSchemaNames;
  limit?: number;

  // TODO: Not sure is it required fields?
  meetingRoomCapacity: number;
  multiRooms: boolean;
  roomType: ERoomType;
  startDate: string;
  endDate: string;
};

export type TSearchVenuesDay = {
  bedrooms: TSearchCriteriaExtra[];
  eventType: EEventType;
  checkIn: string;
  checkOut: string;
  // Fields are not required for Pre-Arrival and Post-Event
  foodAndBeverage?: TSearchCriteriaExtra[];
  maxParticipants?: number;
  roomFilters?: TSearchCriteriaRoom[];
};

export type TSearchVenuesPayload = {
  corporateAccountId: number | null;
  limit: number;
  filterDays: TSearchVenuesDay[];
} & TVenuesQuerySearchData;

export type TSearchCriteria = {
  location: boolean;
  cost: number;
  filterDays: TSearchVenuesDay[];
  days?: TSearchVenuesDay[];
  seatingStyle?: ERoomSchemaNames;
} & TVenuesQuerySearchData;

export type TSearchVenuePayload = {
  initialTimeData?: ITimeData[];
  meetingRequestData?: TDay[];
  filterDays?: TSearchVenuesDay[];
} & TVenuesQuerySearchData;

export interface ISearchedVenue {
  id?: number;
  accommodationBuildingType: TVenueType;
  accommodationId: number;
  accommodationType: EAccommodationType;
  coverImageLink: string;
  currency: string;
  hasPrice: boolean;
  location: ILocation;
  name: string;
  pointsOfInterest: IPointOfInterest[];
  totalBedrooms: number;
  totalMeetingRooms: number;
  greenStayInfo: boolean | null;
  amountOfStars: number | null;
}

export interface IVenuesListResponse {
  pageItems: ISearchedVenue[];
  size: number;
  totalFilteredRecords: number;
}

interface IRoomFilters {
  accommodationId: number;
  checkIn: string;
  checkOut: string;
  priceDay: number;
  priceHour: number;
  priceHalfDay: number;
  setupStyle: ERoomSchemaNames;
  totalPrice: number;
  unitPrice: number;
  extrasPrice: number;
  capacity: number;
  unitId: number;
  position: number;
}

interface IFilterData {
  bedroomsTotalPrice: number;
  checkIn: string;
  checkOut: string;
  filtersTotalPrice: number;
  foodAndBeverageTotalPrice: number;
  roomFilters: IRoomFilters[];
}

export interface ICalculatedVenue {
  accommodationId: number;
  totalPrice: number;
  type: EVenueType;
  filterData: IFilterData[];
  isOnboarded: boolean;
}

export type IMergedVenue = ISearchedVenue & ICalculatedVenue;

export type TSearchCriteriaExtra = {
  extraId?: number;
  quantity: number;
  extraType?: EResourcesType;
  code: EResourcesCode;
  accommodationExtraId?: number;
};

export type TSearchCriteriaRoom = {
  unitId?: number;
  checkIn: string;
  checkOut: string;
  capacity: number;
  extras: TSearchCriteriaExtra[];
  setupStyle: ERoomSchemaNames;
  position: number;
};

export type TSearchCriteriaRooms = {
  rooms: TSearchCriteriaRoom[];
};

// multi-search
export type TSelectedOptions = {
  options: IExtrasOption[];
  ids: number[];
  data: Record<string, any>;
};

export interface IData {
  accommodation: number[];
  [key: string]: number | number[];
}

export type TMeetingRoomItem = {
  timeStart: string;
  timeEnd: string;
  participants: number;
  equipmentData: TSelectedOptions;
  seatsSetup: ERoomSchemaNames;
};

export type TDay = {
  rooms: TMeetingRoomItem[];
  foodBeverageData: TSelectedOptions | null;
  accommodationData: TSelectedOptions | null;
  isPreMeet: boolean;
  isPostMeet: boolean;
  isSectionExpanded: boolean;
  participants: number;

  startDate: string;
  endDate: string;
};

export type TSummaryExtra = {
  checkIn: string;
  checkOut: string;
  participants: number;
  seatsSetup: ERoomSchemaNames;
  equipments: [string, string][];
};

export type TSummaryData = {
  roomsData: TSummaryExtra[];
  isPreMeet: boolean;
  isPostMeet: boolean;
  foodBeverage: [string, string][] | null;
  accommodation: [string, string][] | null;
  isSectionExpanded: boolean;
  dayParticipants: number;
  startDate: string;
  endDate: string;
};

export type TBedroomType = 'single' | 'double';

export type TGroupRoomsItem = {
  isEnabled: boolean;
  qty: number;
  withBreakfast: boolean;
};

export type TGroupRooms = {
  single: TGroupRoomsItem;
  double: TGroupRoomsItem;
};

export interface ISearchTemplateConfig {
  meet: boolean;
  food: boolean;
  room: boolean;
}

export const searchTemplateConfig: Record<string, ISearchTemplateConfig> = {
  [ERoomType.GROUPS]: {meet: false, food: false, room: true},
  [ERoomType.MEETING_ROOM]: {meet: true, food: false, room: false},
};

export interface IUpdateParticipantsArgs {
  dayIndex: number;
  participants: number;
  isFoodBeverageEnabled?: boolean;
  isCustomerChangedFoodAndBeverage?: boolean;
  isAccommodationEnabled?: boolean;
  isCustomerChangedAccommodation?: boolean;
  formData?: Record<string, any>;
}

export interface IUseMeetingRoomData {
  meetingRequestData: TDay[];
  preMeetDaysCount: number;
  maxParticipants: number;
  isGroupSearch: boolean;
  addDay: (isPreMeet: boolean) => () => void;
  setIsSectionExpanded: (
    dayIndex: number,
  ) => (isSectionExpanded: boolean) => void;
  deleteDay: (dayIndex: number, isDayToDelete: boolean) => () => void;
  deleteRoom: (dayIndex: number) => (roomIndex: number) => void;
  deleteAllRoom: (dayIndex: number) => () => void;
  deleteFoodBeverage: (dayIndex: number) => () => void;
  addRoom: (dayIndex: number) => () => void;
  setDefaultRoomList: (dayIndex: number) => () => void;
  setSelectedEquipment: (
    dayIndex: number,
  ) => (
    roomIndex: number,
    formData?: Record<string, any>,
    isAccommodationEnabled?: boolean,
    isCustomerChangedFoodAndBeverage?: boolean,
    isCustomerChangedAccommodation?: boolean,
  ) => void;
  setSelectedFoodBeverage: (
    dayIndex: number,
    checkAccommodationDayOnly?: boolean,
  ) => (formData?: Record<string, any>, dayParticipants?: number) => void;
  addAccommodation: (
    dayIndex: number,
    isPreOrPostMeet: boolean,
    dayParticipants: number,
  ) => (formData?: Record<string, any>) => void;
  removeAccommodation: (dayIndex: number) => () => void;
  bedroomsCatering: TBedroomsCatering;
  handleSetBedroomsCatering: THandleSetBedroomsCatering;
  removeBedroomsCatering: (date: string) => void;
  updateParticipants: (args: IUpdateParticipantsArgs) => void;
}

export enum ESearchInputsId {
  DATE_ID = 'DATE_ID',
  DATE_START_ID = 'DATE_START_ID',
  DATE_END_ID = 'DATE_END_ID',
  PARTICIPANTS_ID = 'PARTICIPANTS_ID',
  REQUIREMENTS = 'REQUIREMENTS',
}
