import React, {useMemo} from 'react';
import _orderBy from 'lodash/orderBy';
import _differenceWith from 'lodash/differenceWith';

import {BookingUnitCardExtrasRow} from './BookingUnitCardExtrasRow';

import {compareExtraWithOrder} from 'view/venue/NW2BookingEdit/helpers';
import {EBookingSummaryTheme} from 'view/components/NW2SummaryBlock/types';
import {moveToEndWhere} from 'view/venue/helpers/spaces';
import {ISummaryExtra} from 'types/dto/IBooking.types';
import {
  EBedroomExtrasNames,
  EResourcesCode,
  EResourcesType,
} from 'types/dto/IExtras.type';
import {IBookedExtra} from 'types/venue';

import {ExtrasTitle} from './NW2BookingUnitCard.styles';
import {BEDROOMS_NAMES_FROM_BE} from 'constants/bedrooms';
import {IExtraResponse} from 'types/dto/IPublicVenue';
import {useVenueDetailsExtras} from 'view/venue/hooks/useVenueDetailsExtras';

type ExtraUnitsProps = {
  extrasList: ISummaryExtra[];
  currency: string;
  isPriceHidden?: boolean;
  bookedExtras?: Partial<IBookedExtra>[] | undefined;
  editMode?: boolean;
  colorTheme?: EBookingSummaryTheme;
  title?: string;
  isConfirmationPage?: boolean;
};
const SummaryExtrasList = ({
  extrasList,
  currency,
  isPriceHidden,
  bookedExtras,
  title,
  editMode = false,
  colorTheme = EBookingSummaryTheme.DARK,
  isConfirmationPage,
}: ExtraUnitsProps) => {
  /**
   * todo can't change to useVenueDetailsData because IMultiDayPublicVenue hasn't extras... clarify what to do
   * looks like we need to have useVenueDetailsExtras hook
   */
  const {venueExtras} = useVenueDetailsExtras();

  const removedExtraList =
    editMode && bookedExtras?.length
      ? _differenceWith(bookedExtras, extrasList, (a, b) => a.code === b.code)
      : [];

  const checkIsSomeFoodBeverage = (list: ISummaryExtra[]) =>
    list.some(({extraType}) => extraType === EResourcesType.FOOD_AND_BEVERAGE);

  const foodBeverageTitle =
    checkIsSomeFoodBeverage(extrasList) ||
    checkIsSomeFoodBeverage(removedExtraList);

  const sortedExtraList = useMemo(() => {
    const filteredList = isConfirmationPage
      ? extrasList
      : extrasList.filter(
          (extraItem) =>
            extraItem.chosenQuantity ||
            extraItem.bookedQuantity ||
            (extraItem as IExtraResponse).quantity,
        );

    const orderByField = (
      list: ISummaryExtra[],
      field: string[],
      order: 'asc' | 'desc',
    ) => _orderBy(list, field, [order]);

    const isBedrooms = filteredList.some(
      ({extraType}) => extraType === EResourcesType.BEDROOM,
    );

    const list = isBedrooms
      ? filteredList
      : isConfirmationPage
      ? orderByField(filteredList, ['name'], 'desc')
      : orderByField(filteredList, ['totalPrice'], 'asc');

    return moveToEndWhere(list, 'code', EResourcesCode.WIFI);
  }, [extrasList, isConfirmationPage]);

  return (
    <>
      {title && <ExtrasTitle>{title}</ExtrasTitle>}
      {foodBeverageTitle && <ExtrasTitle>food & beverage</ExtrasTitle>}
      {sortedExtraList.map((item, idx) => {
        const {
          name,
          extraName,
          totalPrice,
          chosenQuantity,
          bookedQuantity,
          calculatedTotalPrice,
          code,
          extraType,
        } = item;

        const quantity =
          chosenQuantity ||
          bookedQuantity ||
          (item as IExtraResponse).quantity ||
          0;

        const {isHighlighted, iconArrow} = compareExtraWithOrder(
          code,
          quantity,
          calculatedTotalPrice,
          bookedExtras,
          extrasList,
          editMode,
        );

        const extraItem = venueExtras.find((item) => item.code === code);
        const price = extraItem?.price || 0;

        const nameValue =
          name || BEDROOMS_NAMES_FROM_BE[extraName as EBedroomExtrasNames];

        const isBedroom =
          extraType === EResourcesType.BEDROOM ||
          Object.values(BEDROOMS_NAMES_FROM_BE).includes(name as string);

        //to hide FREE on registered venue when price for extras is not provided
        // const noPriceShown =
        //   (totalPrice === 0 && extraItem?.priceType === null) ||
        //   !(extraItem as IExtraResponse)?.isEnabled;
        // ||
        // (isBedroom && !isConfirmationPage);

        const priceToShow =
          isBedroom && isConfirmationPage
            ? calculatedTotalPrice
            : isBedroom
            ? totalPrice
            : isConfirmationPage
            ? calculatedTotalPrice
            : price * quantity;

        return (
          <BookingUnitCardExtrasRow
            key={`${code}-${idx}`}
            currency={currency}
            qty={quantity}
            name={nameValue}
            isPriceHidden={isPriceHidden || (isBedroom && !totalPrice)}
            totalPrice={priceToShow}
            calculatedTotalPrice={
              isConfirmationPage ? calculatedTotalPrice : price * quantity
            }
            isHighlighted={isHighlighted}
            iconArrow={iconArrow}
            editMode={editMode}
            colorTheme={colorTheme}
            isBedroom={isBedroom}
          />
        );
      })}

      {removedExtraList.map(
        ({code, extraName, totalPrice, bookedQuantity}, idx) => (
          <BookingUnitCardExtrasRow
            key={`${code}-${idx}`}
            currency={currency}
            qty={bookedQuantity}
            name={
              BEDROOMS_NAMES_FROM_BE[extraName as EBedroomExtrasNames] ||
              extraName
            }
            isPriceHidden={isPriceHidden}
            totalPrice={totalPrice}
            calculatedTotalPrice={totalPrice}
            editMode={editMode}
            colorTheme={colorTheme}
            isRemoved
          />
        ),
      )}
    </>
  );
};

export default SummaryExtrasList;
