import React from 'react';
import styled from 'styled-components';

import {
  fontSizeXXSm,
  lineHeightXSm,
  fontWeightNormal,
  NW2Gray500Color,
  offsetXXSm,
  borderRadiusDef,
} from 'constants/styleVars';
import Icon from 'view/components/Icon';
import NW2PopoverDrawer from 'view/components/NW2PopoverDrawer';

const StarsCounter = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const StarsLabel = styled.div`
  display: inline-block;
  font-size: ${fontSizeXXSm};
  line-height: ${lineHeightXSm};
  font-weight: ${fontWeightNormal};
  color: ${NW2Gray500Color};
  white-space: nowrap;
`;

type TProps = {
  starsAmount: number | null;
};

const NW2StarsCount = ({starsAmount}: TProps) => {
  if (!starsAmount) return null;

  const tooltipText =
    'The stars are based on the hotels own assessment and on the experience of HRS and HRS customers. You can find details in our terms and conditions.';

  return (
    <StarsCounter>
      <div>
        {new Array(starsAmount).fill(0).map((_, index) => (
          <Icon key={index} icon='HOTEL_STAR' />
        ))}
      </div>
      <NW2PopoverDrawer
        body={<>{tooltipText}</>}
        placement='bottom'
        isMobile={false}
        popoverWidth='200px'
        popoverBodyStyle={{
          padding: offsetXXSm,
        }}
        overlayInnerStyle={{
          borderRadius: borderRadiusDef,
        }}
      >
        <StarsLabel>HRS Stars</StarsLabel>
      </NW2PopoverDrawer>
    </StarsCounter>
  );
};

export default NW2StarsCount;
