import {useCallback, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';

import {setPageForVenuesSearch} from 'store/search/searchSlice';
import {VENUE_LIST_ITEMS_PER_PAGE} from 'constants/app';
import {IMergedVenue} from 'types/search';
import {EAccommodationType} from 'types/dto/IPublicVenue';
import {useAppSelector} from 'store/hooks';
import {useGetSearchDateDifference} from 'view/venue/hooks/useGetSearchDateDifference';
import {ALLOWED_DAYS_DIFFERENCE_FOR_RFP} from 'constants/days';
import {ERoomType} from 'types/dto/ERoomType.type';
import {ESortOption} from '../components/NW2VenuesSort/constants';

const splitVenues = (pageNumber: number, venues: IMergedVenue[]) => {
  const endValue = VENUE_LIST_ITEMS_PER_PAGE * (pageNumber + 1);
  return venues.slice(0, endValue);
};

type TProps = {
  data: IMergedVenue[];
};
function useFilterVenuesList({data = []}: TProps) {
  const dispatch = useDispatch();

  const roomType = useAppSelector(({search}) => search.searchCriteria.roomType);
  const page = useAppSelector(({search}) => search.searchCriteria.page || 0);

  const sortOption = useAppSelector(({filters}) => filters.venuesSortOption);

  const isWorkDesk = roomType === ERoomType.WORK_SPACE;
  const isCorporateOffice = (accommodationType: EAccommodationType) =>
    accommodationType === EAccommodationType.CORPORATE_OFFICE;

  const [diffInDays] = useGetSearchDateDifference();

  const filteredGlobalList = useMemo(() => {
    const venuesWithPrice = data.filter(({totalPrice}) => !!totalPrice);
    const venuesWithoutPrice = data.filter(({totalPrice}) => !totalPrice);

    const sortByDistance = (a: IMergedVenue, b: IMergedVenue) => {
      // sorting by location distance
      if (Number(a.location.distance) < Number(b.location.distance)) return -1;
      if (Number(a.location.distance) > Number(b.location.distance)) return 1;
      return 0;
    };

    // venues with total price - lowest to highest
    const venuesSortedByPrice = venuesWithPrice.sort((a, b) => {
      if (a.totalPrice < b.totalPrice) return -1;
      if (a.totalPrice > b.totalPrice) return 1;
      // sort by location distance if same price
      if (Number(a.location.distance) < Number(b.location.distance)) return -1;
      if (Number(a.location.distance) > Number(b.location.distance)) return 1;
      return 0;
    });

    // venues onboarded (registered and PW) - per distance from POI, close to far
    const venuesOnboarded = venuesWithoutPrice
      .filter(({isOnboarded}) => isOnboarded)
      .sort(sortByDistance);

    // venues not onboarded with image - per distance from POI, close to far
    const venuesNotOnboardedWithImage = venuesWithoutPrice
      .filter(
        ({isOnboarded, coverImageLink}) => !isOnboarded && !!coverImageLink,
      )
      .sort(sortByDistance);

    // venues not onboarded without image - per distance from POI, close to far
    const venuesNotOnboardedWithoutImage = venuesWithoutPrice
      .filter(
        ({isOnboarded, coverImageLink}) => !isOnboarded && !coverImageLink,
      )
      .sort(sortByDistance);

    let groupedList = [
      ...venuesSortedByPrice,
      ...venuesOnboarded,
      ...venuesNotOnboardedWithImage,
      ...venuesNotOnboardedWithoutImage,
    ];

    if (sortOption === ESortOption.DISTANCE) {
      const corporateOfficeVenues = groupedList.filter(({accommodationType}) =>
        isCorporateOffice(accommodationType),
      );
      const otherVenues = groupedList.filter(
        ({accommodationType}) => !isCorporateOffice(accommodationType),
      );
      groupedList = [
        ...corporateOfficeVenues.sort(sortByDistance),
        ...otherVenues.sort(sortByDistance),
      ];
    }

    return diffInDays < ALLOWED_DAYS_DIFFERENCE_FOR_RFP && !isWorkDesk // todo remove isWorkDesk after align tabs switch flow
      ? groupedList.filter(
          ({accommodationType, totalPrice}) =>
            !!totalPrice || isCorporateOffice(accommodationType),
        )
      : groupedList;
  }, [data, diffInDays, isWorkDesk, sortOption]);

  const [pageNumber, setPageNumber] = useState(0);

  const getVenuesWithPagination = useCallback(() => {
    const updatedPageNumber = page + 1;
    setPageNumber(updatedPageNumber);
    dispatch(setPageForVenuesSearch(updatedPageNumber));
  }, [dispatch, page]);

  const filteredVenuesList = useMemo(
    () => splitVenues(pageNumber, filteredGlobalList),
    [filteredGlobalList, pageNumber],
  );

  return {
    getVenuesWithPagination,
    totalRecords: filteredGlobalList.length,
    filteredVenuesList,
  };
}

export default useFilterVenuesList;
